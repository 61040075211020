.container {
    position: relative;
    top: 72px;
    width: 100%;
}

.video_results {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    position: relative;
    padding: 12px;
}

.video_results > div { 
    margin-top: 24px;
}

.pending_content {
    width: 100%;
    height: 50vh;
}

.pending_content > p {
    text-align: center;
    margin-top: 25vh;
}



@media (min-width: 720px) {
    .container {
  
    }
    
    .video_results {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .video_results > div { 
        margin-top: 24px;
        margin-left: 18px;
    }
}
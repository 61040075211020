.container {
	width: 100%;
	margin: 0px;
	position: relative;
	left: 0px;
	right: 0px;
	top: 0px;
	background-repeat: no-repeat;
	background-size: cover;
	margin-bottom: 32px;
}

.main {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.copy {
	margin: 16px 16px;
}

.item {
	display: none;
}

.item > ul {
	margin-top: 32px;
	padding: 0px;
}

.item > ul > li {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border-bottom: 1px solid #66686a;
	padding-bottom: 12px;
	margin-top: 16px;
}

.item > ul > li:last-child {
	border-bottom: none;
}

.item > ul > li > p {
	margin-top: 1px;
}

.item > ul > li > img {
	margin-right: 12px;
	width: 24px;
	height: 24px;
}

.mentor_rates {
	display: flex;
	flex-direction: column;
}

.mentor_rates > li {
	height: 58px;
	width: 100%;
	display: flex;
	justify-content: space-between !important;
	flex-direction: row !important;
	padding-bottom: 12px;
	height: fit-content !important;
	padding-top: 0px;
	margin-top: 8px;
}

.mentor_avatars {
	display: flex;
	flex-direction: row;
	width: 170px;
	justify-content: space-between;
	margin-top: 6px;
}

.mentor_avatars > p {
	font-size: 10px;
	margin-top: 8px;
	margin-left: 6px;
}

.mentor_avatars > img {
	width: 32px;
	height: 32px;
}

@media (min-width: 900px) {
	.container {
		background-image: url("../../../../../images/people.png");
		margin-bottom: 0px;
	}

	.main {
		flex-direction: row;
		max-width: 970px;
		justify-content: flex-end;
		margin: 0px auto 42px auto;
		position: relative;
		-webkit-justify-content: flex-end;
		align-items: flex-end;
		-webkit-align-items: flex-end;
	}

	.copy {
		position: absolute;
		margin: 0;
		top: 50%;
		left: 0px;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		max-width: 482px;
	}

	.item {
		display: block;
	}

	.testimonial {
		margin-top: 24px;
		background-color: #fff;
		height: 145px;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		padding: 18px 64px;
	}
}

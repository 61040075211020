.container > div {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.input {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.label {
  margin-left: 4px;
  text-align: left;
  float: left;
  position: relative;
  top: 3px;
  display: inline-flex;
  font-size: 12px;
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
}

.error {
  margin-top: 2px;
  font-size: 12px;
  color: var(--error-red);
}

@media (min-width: 900px) {
  .label {
    font-size: 14px;
    top: 6px;
    margin-left: 6px;
  }

  .input {
    margin: 7px 0px 0px 0px;
  }

  .error {
    margin-top: 4px;
    font-size: 14px;
    margin-left: 5px;
  }
}

.content_container {
	max-width: 1120px;
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	top: 124px;
	position: relative;
}

/* STATS */
.team_stats {
	min-height: 204px;
	background-color: #1a1c1d;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
}

.team_stats > div {
	padding: 24px;
}

.team_stats_header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid #000;
	padding: 18px 24px;
}

.team_stats_header > button {
	border-radius: 28px;
	height: 24px;
	padding: 2px 20px;
	font-family: "GT-America-Standard";
}

.team_stats_header > h4 {
	margin-top: 4px;
}

.stats_container {
	display: flex;
	width: 100%;
	justify-content: space-evenly;
	margin: 24px 0px;
}

.stats_container > div > * {
	text-align: center;
}

/* Invite */
.invite_container {
	background-color: #1a1c1d;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
}

.invite_header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid #000;
	padding: 18px 24px;
}

.form {
	padding: 18px 24px 24px 24px;
}

.name_fields {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* margin-bottom: 18px; */
}

.name_fields > button {
	min-width: 124px;
	margin-top: 22px;
	height: 38px;
}

/* Team */
.team_member_container {
	width: 100%;
	display: inline-flex;
	flex-wrap: wrap;
	gap: 24px;
	margin: 16px 0px;
}

.member {
	background-color: #1a1c1d;
	width: 544px;
	/* height: 305px; */
	border-radius: 8px;
}

.member_info {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 24px;
}

.member_info > div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.member_info > div > img {
	width: 52px;
	height: 52px;
	border-radius: 50%;
}

.member_info > div > div {
	margin-left: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.member_info > div > div > p {
	font-size: 14px;
	color: #bfbfbf;
}

.member_info > button {
	width: 82px;
	height: 34px;
	background: #000000;
	border-radius: 32px;
	padding: 0px;
	font-family: "GT-America-Standard";
}

.member > ul {
	list-style: none;
	margin: 0px;
	padding: 0px 24px 24px 24px;
}

.member > ul > li {
	border-bottom: 1px solid #000;
	display: flex;
	justify-content: space-between;
	padding: 8px 0px;
}

.confirmRemoveContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	background-color: rgba(0, 0, 0, 0.6);
}

.confirmRemoveContent {
	margin: auto;
	margin-top: 20%;
	width: 420px;
	height: 336px;
	background-color: #fff;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 32px 18px;
}

.confirmRemoveContent > * {
	margin: auto;
}

.confirmRemoveContent > h3 {
	color: #000;
}

.confirmRemoveContent > img {
	width: 54px;
	height: 54px;
	border-radius: 50%;
}

@media (max-width: 700px) {
	.content_container {
		padding: 12px;
		top: 78px;
	}
	.team_stats_header > button {
		max-width: 90px;
	}

	.stats_container {
		flex-direction: column;
	}

	.stat {
		margin: 12px 0px;
	}

	.name_fields {
		flex-direction: column;
	}
	.name_fields > button {
		padding-top: 8px !important;
	}

	.member_info > button {
		width: 70px;
	}

	.member_info > div > div {
		width: 180px;
		word-wrap: break-word;
	}
}

.mobile_profile_selector {
    display: block;
    padding: 8px 12px;
    width: 100%;
    background-color: ;
}

@media (min-width: 900px) {
    .mobile_profile_selector {
        display: none;
    }
}
/* webapp/src/components/Molecules/SessionStatusTag/SessionStatusTag.module.css */
.completedTag {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #27AE60;
}

.canceledTag {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #EB5757;
}

.dateInfo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dateInfo p {
    color: var(--Grey-Text-on-Black, #bfbfbf);
    font-family: "GT America";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
}
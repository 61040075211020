.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);

    /* top: 0px;
    bottom: 0px;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: rgba(0,0,0,0.7); */
}

.modalContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
    border-radius: 8px;
    background: #1a1c1d;
}

.videoContainer {
    position: relative;
    height: 252px;
    width: 100%;
}

.video {
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0px 0px;
    z-index: 0;
}

.play_button {
    display: inline-flex;
    padding: 10px 24px 10px 0px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    border-radius: 52px;
    border: 2px solid #5440f0;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 92px;
    right: calc(50% - 78px);
    width: 156px;
    z-index: 100;
}

.play_button:hover {
    background: var(--primary-colour-hover, #5440f0);
    border: 2px solid var(--primary-colour-hover, #5440f0);
    box-shadow: none;
}

.play_button>span {
    color: var(--Gray-25, #e0e1e1);
     font-family: var(--regular-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.dismissButton {
    height: 48px;
    display: flex;
    padding: 16px 38px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 3px;
    background: transparent;
    border: 1px solid var(--Gray-700, #444547);
}

.dismissButton>span {
    color: var(--White, #fff);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 137.5% */
}

.dismissButton:hover {
    background: var(--Gray-700, #444547);
}

@media all and (min-width: 900px) {
    .modalContainer {
        display: flex;
        width: 582px;
        padding: 48px;
        flex-direction: column;
        align-items: center;
        gap: 48px;
    }

    .content>h2 {
        font-size: 32px;
    }
}

@media all and (min-width: 1320px) {
    .container {}
}
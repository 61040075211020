.container {
	width: 100%;
	height: fit-content;
	background-color: #1a1c1d;
	display: flex;
	flex-direction: column;
    gap: 12px;
    border-radius: 3px;
}

.title_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    align-items: flex-start;
}

.title {
    color: #fff;
    font-size: 24px;
    vertical-align: text-top;
    width: 100%;
}

.edit_button {
    border: 1px solid #fff;
    border-radius: 4px;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    padding: 6px;
    opacity: 0.5;
    cursor: pointer;
}

.edit_button > img {
    width: 18px;
    height: 18px;
    filter: brightness(0) invert(1);
}

.edit_button:hover {
    border: 1px solid #fff;
    opacity: 0.8;
}

.description {
    color:var(--body-text-on-black);
    font-size: 16px;
    width: 100%;
}

.progress_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
}

.progress_stats {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4px;
}

.progress_stats > span {
    font-size: 14px;
    color:var(--body-text-on-black);
    font-family: "GT-America-Standard";
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

.successCheckmark {
  width: 80px;
  height: 115px;
  margin: 0px auto;
}
.successCheckmark .checkIcon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border: 4px solid var(--primary-colour-presses);
}
.successCheckmark .checkIcon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}
.successCheckmark .checkIcon::after {
  top: 0;
  left: 30px;
  width: 60px;
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  -webkit-animation: rotate-circle 5s ease-in;
  animation: rotate-circle 5s ease-in;
}
.successCheckmark .checkIcon::before,
.successCheckmark .checkIcon::after {
  content: "";
  height: 100px;
  position: absolute;
  background: #ffffff;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.successCheckmark .checkIcon .iconLine {
  height: 5px;
  background-color: var(--primary-colour);
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}
.successCheckmark .checkIcon .iconLine.lineTip {
  top: 46px;
  left: 14px;
  width: 25px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: iconlineTip 1s;
  animation: iconlineTip 1s;
}
.successCheckmark .checkIcon .iconLine.lineLong {
  top: 38px;
  right: 8px;
  width: 47px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: iconlineLong 1s;
  animation: iconlineLong 1s;
}
.successCheckmark .checkIcon .iconCircle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border: 4px solid rgba(54, 41, 156, 0.6);
}
.dark .checkIcon .iconFix {
  background-color: transparent !important;
}

.dark .checkIcon::before,
.dark .checkIcon::after {
  background-color: transparent !important;
}

.dark .checkIcon .iconFix {
  background-color: transparent !important;
}

@-webkit-keyframes rotate-circle {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

@keyframes rotate-circle {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@-webkit-keyframes iconlineTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes iconlineTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@-webkit-keyframes iconlineLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes iconlineLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

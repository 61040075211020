.copyToClipboard {
  width: 90%;
  margin: 18px auto;
}

.input {
  width: 100%;
}

.button {
  width: 100%;
  margin-bottom: 18px;
}


/* .section {
	background-color: var(--module-bg-colour);
} */
.headerContent {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}


.calendarList {
	margin-top: 12px;
	display: flex;
	flex-direction: column;
}

.calendarListItem {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 4px;
}

.calendarListItem > div {
	margin-top: 5px !important;
}

.calendarListItem > p {
	margin-left: 4px;
	margin-bottom: 2px;
	color: var(--field-outline-colour);
}


@media (max-width: 400px) {
	.headerContent {
		flex-direction: column;
	}

	.headerContent > button {
		margin-top: 12px;
	}
  }
.container {
	position: relative;
}

.container>img {
	margin-left: 4px;
	margin-top: -6px;
}

.container>img:hover+.toolTip {
	display: inline;
}

.toolTip {
	display: none;
	color: #000;
	background-color: #f4f4f4;
	padding: 4px 8px;
	border-radius: 8px;
	min-width: 180px;
	position: absolute;
	left: 24px;
	font-size: 12px;
}

@media all and (min-width: 900px) {
    .container {

    }
}

@media all and (min-width: 1320px) {
    .container {

    }
}
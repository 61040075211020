.container > div {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 3px 8px 3px 3px;
}

.label {
    text-align: left;
    float: left;
    position: relative;
    top: 0px;
    display: inline-flex;
    color: #bfbfbf;
    padding-top: 2px;
}

.input {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--primary-colour);
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    border-radius: 5px;
}

.input::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    background-color: #fff;
}

.input:checked::before {
    transform: scale(1);
  }
.background {
  z-index: -10;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.6s ease-in-out;
}

.background_active {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-block;
}

.container {
  z-index: 200;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  margin: 0px;
  background: #1a1c1d;
  padding: 24px 12px;
  border-radius: 3px;
  display: none;
}

.container_active {
  display: block;
}

@media all and (min-width: 900px) {
  .background {
  }

  .container {
    display: block;
    top: 0px;
    bottom: 0px;
    width: 572px;
    right: -572px;
    left: auto;
    transition: right 0.3s ease-in-out;
    padding: 24px;
    border-radius: 0px 0px 4px;
  }

  .container_active {
    right: 0px;
  }
}

@media all and (min-width: 1320px) {
  .container {
  }
}

:root {
    --primary-colour: #5440f0;
    --primary-colour-hover: #4837cf;
    --primary-colour-presses: #36299c;
    --pills-bg-colour: #ddd9fc;
    --error-colour: #fb836f;
    --error-colour-dark: #ff644d;
    --black: #000;
    --module-bg-dark: #1a1c1d;
    --module-bg-colour: #25282a;
    --body-text-on-white: #66686a;
    --body-text-on-black: #bfbfbf;
    --disabled-colour: #bfbfbf;
    --field-outline-colour: #e9e9ea;
    --section-bg-colour: #f4f4f4;
    --corner-radius: 3px;
    --bold-font: "GT-America-Extended-Bold";
    --regular-font: "GT-America-Standard";
}

/*
@media queries for screens in range (500px - 900px)
@start
*/

@media (max-width: 900px) {
    .content-container {
        margin-top: 24px;
    }

    .account-content-container {
        width: 100%;
        margin: 0px;
        padding: 72px 6px 0px 6px;
        height: 100%;
    }

    .vertical-nav-content {
        border: none !important;
        height: 50px;
    }

    .vertical-nav {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        background: var(--black);
        position: fixed;
        left: 0px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        z-index: 9;
        height: 50px;
        /* top: 144px; */
    }

    .vertical-nav-tab {
        flex-direction: row;
        border-radius: 0px;
        margin: 0px;
        width: auto;
        height: auto;
        background: none;
        font-size: 14px;
        margin-top: 5px;
    }

    .account-header {
        width: 100%;
        margin-left: 0px;
        border-bottom: none;
        padding: 0px;
        /* top: 72px; */
        height: 72px;
    }

    .account-header-content {
        display: none;
    }

    .component-window {
        position: absolute;
        margin: auto;
        /* left: 0; */
        width: 100%;
        padding: 0px 6px 42px 6px;
    }

    .component-tab {
        width: auto;
    }

    .checkbox {
        align-items: center;
    }

    .profile-section-media {
        display: block;
    }

    .profile-video {
        width: auto;
        margin-top: 20px;
    }

    .profile-photo {
        width: auto;
    }

    .media-content {
        background: var(--black);
    }

    button.btn-small {
        width: inherit;
    }

    .booking-time {
        display: block;
        margin: auto;
        width: 100%;
        text-align: -webkit-center;
    }

    .selected,
    .vertical-nav-tab:hover {
        background-color: var(--black);
        border-bottom: 1px solid var(--primary-colour);
    }

    .account-title {
        font-size: 16px;
    }

    .input-with-button>button {
        height: auto;
        width: auto;
        padding: 10px;
        margin-left: 10px;
        margin-top: 0px;
    }

    .modal-helper-text {
        margin-top: 10px;
    }

    .input-with-button>div>span {
        right: 200px;
    }

    .highlight-input>div {
        width: 94%;
    }

    .highlight-input {
        display: block;
    }

    .modal-content>.header {
        display: flex;
    }

    .modal-action>button {
        width: 100%;
    }

    .radio-container {
        margin-top: 12px;
    }

    .bold-header {
        margin-bottom: 12px;
    }

    .input-btn-icon-padding {
        padding-right: 32%;
    }

    .input-two-icon-padding {
        padding-right: 29%;
    }

    .input-icon-padding {
        padding-right: 18%;
    }

    .input-btn-padding {
        padding-right: 24%;
    }

    .login-button-container {
        margin-top: 15px;
    }

    .login-action-container {
        display: flex;
        align-items: baseline;
    }

    .login-menu {
        min-width: 400px;
        width: auto;
    }

    .profile-about-section {
        border-radius: 0px;
    }

    .profile-video-preview>div {
        border-radius: 0px;
    }
}

/*
@media queries for screens in range (500px - 900px)
@end
*/

@media only screen and (max-device-width: 736px) and (orientation: portrait) {
    .header-container {
        height: 62px;
        background: #000000;
        padding: 20px 24px;
    }

    .login-menu {
        display: none;
    }

    .login-action-container {
        display: none;
    }
}

@media only screen and (min-device-width: 736px) {
    .header-container {
        grid-column-start: 1;
        grid-column-end: 4;
    }
}

/*
@media queries for screens less than 501px
@start
*/
@media (max-width: 500px) {
    .account-content-container {
        margin: 0px;
        padding: 72px 0px 0px 0px;
    }

    .s-account-content {
        margin-bottom: 100%;
    }

    .vertical-nav {
        justify-content: flex-start;
    }

    .input-with-button>div>span {
        right: 130px;
    }

    /* .profile-header-content {
    min-height: 200px;
  } */
}

/*
@media queries for screens less than 501px
@end
*/
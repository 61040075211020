.container {
  width: 100%;
}

.tag_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;
}

.tag_container > * {
  margin-right: 8px;
  margin-bottom: 8px;
}

.tag {
  border: 1px solid transparent;
  cursor: pointer;
  background-color: #000;
}

.tag:hover {
  border: 1px solid var(--body-text-on-white);
}

.tag_content {
  display: flex;
  justify-content: space-between;
}

.tag_content > img {
  height: 8px;
  width: 8px;
  margin-left: 8px;
  margin-top: 3px;
}

.error {
  margin-top: 3px;
  font-size: 12px;
  color: var(--error-red);
}

@media all and (min-width: 900px) {
  .container {
  }
}

@media all and (min-width: 1320px) {
  .container {
  }
}

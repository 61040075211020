.button {
	/* position: absolute; */
	top: 12px;
	right: 12px;
	border: 1px solid #fff;
	border-radius: 4px;
	box-sizing: border-box;
	width: 32px;
	height: 32px;
	padding: 9px;
	opacity: 0.5;
	cursor: pointer;
	padding-top: 7px;
}

.button > img {
	width: 12px;
	height: 12px;
	filter: brightness(0) invert(1);
}

.button:hover {
	border: 1px solid #fff;
	opacity: 0.8;
}
@media all and (min-width: 900px) {
    .container {

    }
}

@media all and (min-width: 1320px) {
    .container {

    }
}

.picker {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.pill {
    height: 32px;
    border-radius: 28px;
    background: none;
    color: var(--body-text-on-black);
    border: var(--body-text-on-black) 2px solid;
    padding: 0px 12px 0 18px;
    font-family: "GT-America-Standard";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: inherit;
    margin-right: 12px;
    margin-bottom: 12px;
}

.pill > p {
    white-space: nowrap;
    padding: 3px 10px 3px 0px;
}

.pill>img {
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin-top: 10px;
}

.add {
	width: 32px;
	height: 32px;
	cursor: pointer;
}
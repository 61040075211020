.sessionContainer {
	width: 100%;
}

.contentContainer {
	position: relative;
	height: 100%;
	width: 100%;
	padding: 96px 72px 0px 72px;
	display: block;
	margin-bottom: 24px;
}

@media (max-width: 900px) {
	.contentContainer {
		margin: 0px;
		padding: 72px 0px 0px 0px;
		margin-top: 12px;
	}
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
}

.form_container {
  padding: 24px;
  margin-top: 32px;
}

@media all and (min-width: 900px) {
  .container {
    padding: 0px;
    padding-left: 260px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media all and (min-width: 1200px) {
  .container {
    padding: 0px;
  }
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  gap: 4px;
  width: 100%;
  height: 56px;
  /* Black / Standard */
  background: #000000;
  /* Purple / Standard */
  border-radius: var(--corner-radius);
  cursor: pointer;
}

.container[data-selected="true"] {
    border: 1px solid #5440f0;
}

.container > div {
  width: fit-content;
  display: flex;
  flex-direction: row;
}

.credits > h3 {
   font-family: var(--regular-font);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  margin-left: 12px;
}

.selected {
  /* box-sizing: border-box; */
  padding: 0px;
  width: 16px;
  height: 17px;
}

.unselected {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #66686a;
}

.cost {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
}

.cost > p {
  font-family: var(--regular-font);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #bfbfbf;
}

@media (min-width: 768px) {
  .container {
    flex-direction: column;
    justify-content: space-between;
    height: 112px;
    max-width: 152px;
  }
  .container > div {
    width: fit-content;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .credits {
    display: flex;
    justify-content: space-between;
    gap: 4px;
  }

  .cost {
    gap: 2px;
  }

  .credits > h3 {
    margin: 0px;
  }
}

@media (min-width: 1024px) {
}

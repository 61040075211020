.container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.container > label {
	font-size: 12px;
	text-transform: capitalize;
	color: var(--body-text-on-white);
}

.container > input {
	width: 100%;
	height: 40px;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 0 10px;
	margin: 5px 0px 0px 0px;
}

.error_label {
	color: var(--error-red);
}

.error_input {
	border: 1px solid var(--error-red) !important;
}

.error {
	margin-top: 3px;
	font-size: 12px;
	color: var(--error-red);
}

@media (min-width: 900px) {
	.container > label {
		font-size: 14px;
		text-transform: capitalize;
	}

	.container > input {
		margin: 7px 0px 0px 0px;
	}

	.error {
		margin-top: 4px;
		font-size: 14px;
		margin-left: 5px;
	}
}

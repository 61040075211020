.details_button {
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 32px;
    border: 1px solid #000;
    background: var(--Black, #000);
    display: none;
    cursor: pointer;
}

.details_button:hover {
    background: #1a1c1d;
    border: 1px solid #fff;
}

.chevron {
    margin-left: 5px;
    transition: transform 0.3s ease;
}

@media (min-width: 900px) {
    .details_button {
        display: flex;
        position: absolute;
        right: 76px;
    }
}
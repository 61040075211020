.container {
  margin-top: 72px;
  padding: 24px;
}

.create_collection_modal {
  width: 96%;
  margin: auto;
  margin-top: 8%;
  background-color: var(--module-bg-colour);
  z-index: 100;
}

.overlay {
  background-color: rgba(0,0,0,0.4);
}

@media all and (min-width: 900px) {
  .container {
  }

  .create_collection_modal {
    width: 40%;
    margin: auto;
    margin-top: 15%;
    background-color: var(--module-bg-colour);
    z-index: 100;
  }
}

@media all and (min-width: 1320px) {
  .container {
  }
}

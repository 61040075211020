.container {
  border: 2px solid #000;
  background-color: #000;
  border-radius: 5px;
  margin-right: 3px;
  padding: 24px;
  width: 100%;
}

.containerSelected {
  border: 2px solid var(--primary-colour);
}

.content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: auto;
  width: 100%;
}

.content > p {
  text-align: center;
}

.midText {
  font-family: GT-America-Extended-Bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  margin-top: 2px;
}

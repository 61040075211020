.attachmentIcon {
	width: 16px;
	height: 16px;
}

.container {
	display: flex;
	flex-direction: column;
}

.container > p {
	color: #fff;
	position: relative;
	float: left;
	font-size: 12px;
	text-transform: uppercase;
	font-family: var(--bold-font);
	width: 100%;
	margin-bottom: 8px;
}

.container > div {
	position: relative;
}

.file {
	display: inline-flex;
}

.file > img {
	margin-top: 4px;
}

.file > p {
	margin-left: 5px;
}

.uploadBtn {
	left: 0px;
	position: relative;
	height: 28px;
	min-width: 122px;
	max-width: 200px;
	background-color: transparent;
	border: 1px solid var(--body-text-on-white);
	border-radius: 28px;
	padding: 6px;
	font-family: var(--regular-font);
}

.uploadBtn:hover {
	background-color: #fff;
	color: #000;
	box-shadow: none;
}

.deleteAttachment {
	opacity: 0.7;
	margin-left: 8px;
	margin-bottom: 2px;
	cursor: pointer;
}

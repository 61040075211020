.mobile {
  display: block;
  width: 100%;
  padding: 16px;
  background-color: #1a1c1d;
  border-radius: 3px;
}

.desktop {
  width: 100%;
  display: none;
}

@media all and (min-width: 900px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
}

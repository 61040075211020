.container {
	max-width: 720px;
	background-color: #fff;
	border-radius: 16px;
	max-height: fit-content;
	padding: 24px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

.image_container {
	max-height: 294px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	/* align-items: center; */
	overflow: hidden;
	flex-direction: column;
}

.image_container > img {
	max-width: 100%;
	height: auto;
	width: auto\9; /* ie8 */
}

.content {
	width: 100%;
	padding: 32px 0px 32px 0px;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
}

.content > div > h3 {
	color: #000;
}

.content > div > p {
	margin-top: 12px;
	font-size: 14px;
	color: var(--body-text-on-white);
}

.btn {
	background-color: transparent;
	display: flex;
	cursor: pointer;
	margin-top: 12px;
}

.btn > h4 {
	color: var(--primary-colour);
}

.btn > img {
	margin-top: 4px;
	margin-left: 4px;
	height: 12px;
	width: 12px;
}

@media (min-width: 900px) {
	.container {
		max-height: 342px;
		padding: 24px;
		flex-direction: row;
	}

	.content {
		width: 100%;
		padding: 32px 0px 32px 32px;
		display: flex;
		justify-content: space-around;
		flex-direction: column;
	}

	.image_container {
		min-width: 316px;
	}

	.btn {
		margin-top: 2px;
	}
}

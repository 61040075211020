.container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-colour);
  border-radius: 9999px;
  transition: all 0.3s;
  color: #ffffff;
  min-width: auto;
  padding: 0;
}

.container::hover {
  background-color: var(--primary-colour-hover);
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.25);
}

.container::active {
  background-color: var(--primary-colour-presses);
}

.container::disabled {
  background-color: var(--disabled-colour);
  cursor: default;
  transform: none;
}

@media all and (max-width: 900px) {
  .container {
  }
}

@media all and (max-width: 1320px) {
  .container {
  }
}

.sessionReview {
  width: 100%;
  margin: 12% 0px;
  color: #fff;
  padding: 24px;
}

.introText {
  line-height: 180%;
}

.avatarSection > img {
  border-radius: var(--corner-radius);
}

.reviewHeading {
  font-size: 36px;
  margin-top: 40px;
  font-family: var(--bold-font);
  color: #fff;
  text-align: center;
}
.reviewCompleteHeading {
  font-size: 36px;
  margin-top: 25px;
  font-family: var(--bold-font);
  color: #fff;
  text-align: center;
}
.welcome {
  width: 520px;
  margin-top: 12px;
}

.welcome > p {
  text-align: center;
  font-size: 18px;
}

.firstStep > button {
  margin-top: 20px;
}

.firstStep,
.secondStep,
.thirdStep,
.fourthStep,
.endReview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 650px;
  margin: auto;
}

.firstStep {
  max-width: 720px;
}

.secondStep > .label,
.thirdStep > .label,
.fourthStep > .label {
  font-size: 12px;
  font-family: var(--bold-font);
}

.secondStep > .question,
.thirdStep > .question {
  font-size: 18px;
  font-family: var(--bold-font);
  color: #fff;
  margin-top: 15px;
  text-align: center;
}

.fourthStep > .question {
  font-size: 18px;
  font-family: var(--bold-font);
  color: #fff;
  margin-top: 10px;
  text-align: center;
}
.secondStep > .numBlocks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.secondStep > .numBlocks > button {
  margin-left: 5px;
}

.secondStep > .ratingLabel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 15px;
  width: 100%;
}

.secondStep > .ratingLabel > p {
  font-size: 10px;
  font-family: var(--bold-font);
  color: var(--body-text-on-black);
  margin-left: 5px;
}

.secondStep > button,
.thirdStep > button,
.endReview > button {
  margin-top: 40px;
}

.thirdStep > .blocks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 550px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.thirdStep > .blocks > div {
  margin-left: 20px;
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 29%;
          flex: 0 29%;
}

.skipBtn,
.skipBtn:hover,
.skipBtn:focus,
.skipBtn:active {
  font-size: 12px;
  font-family: var(--bold-font);
  color: #fff;
  margin-top: 15px !important;
  background: none;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0px;
  outline: none;
}

.midCentered {
  width: 450px;
  font-size: 14px;
  margin-top: 12px;
  text-align: center;
}

.textAreaBox {
  width: 100%;
  margin: 12px 0px 24px 0px;
}

.thanks {
  margin-top: 12px;
}

.thanks > p {
  width: 450px;
  font-size: 14px;
  text-align: center;
}

@media (max-width: 900px) {
  .welcome,
  .midCentered,
  .thanks > p {
    width: auto;
  }

  .welcome > p {
    line-height: 24px;
  }
  .thirdStep > .blocks > div > button {
    width: 130px !important;
    height: 130px !important;
  }

  .thirdStep > .blocks > div {
    margin-left: 10px;
    margin-right: 10px;
  }

  .sessionReview {
    padding: 0px 15px;
  }
}

@media (max-width: 700px) {
  .thirdStep > .blocks {
    width: auto;
  }

  .thirdStep > .blocks > div {
    -webkit-box-flex: 0;
        -ms-flex: 0 45%;
            flex: 0 45%;
  }

  .secondStep > .numBlocks > button {
    margin-left: 5px;
    -webkit-box-flex: 0;
        -ms-flex: 0 18%;
            flex: 0 18%;
    margin-top: 5px;
  }

  .secondStep > .ratingLabel {
    display: none;
  }
}
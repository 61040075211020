.proposed_times {
    display: flex;
    width: 100%;
    padding: 8px;
    gap: 12px;
    background-color: #1a1c1d;
    border-radius: 4px;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
}

.proposed_times>h2 {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    /* Title / Extended Bold - 16 */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 22.4px */
}

.proposed_times>h3 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.proposed_times>p {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.proposed_times>button {
    width: 100%;
}

.times_container {
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 50vh;
    height: 100%;
}

.times_container>p {
    color: #fff;
    font-family: "GT America";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.proposed_time_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 16px;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: #000;
}

.proposed_time_container>input {
    margin: 4px;
}

.remove_time_button {
    width: 16px;
    height: 16px;
    background-color: none;
    cursor: pointer;
}

.remove_time_button>svg:hover {
    fill: var(#fff);
}

.proposed_time_content {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.proposed_time_content>span {
    color: #fff;
    font-family: "GT America";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
}

.preferred_time_text {
    color: #BFBFBF;
    font-family: "GT America";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
}

@media (min-width: 900px) {
    .proposed_times {
        margin-top: 16px;
        width: 320px;
        padding: 16px;
        gap: 16px;
    }
}
.nav {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0px;
    position: fixed;
    width: 160px;
    left: calc(50% - 365px);
}

@media (min-width: 900px) {
    .nav {
        margin-top: 16px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.nav_tab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 8px 16px;
    position: static;
    width: 160px;
    height: 38px;
    left: 0px;
    top: 84px;
    background: var(--module-bg-dark);
    border-radius: var(--corner-radius);
    margin: 4px 0px;
    color: #fff;
}

.selected,
.nav_tab:hover {
    background-color: var(--primary-colour-hover);
    color: #fff;
}

.nav_content,
.nav_content:hover {
    font-family: "GT-America-Standard";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    border-radius: var(--corner-radius);
    color: var(--body-text-on-white);
}
.successPage {
    width: 100%;
    height: 420px;
    margin: auto;
    margin-top: 10%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 12px;
}

.successPage>p {
    text-align: center;
    margin-top: 12px;
}

.successPage>h2 {
    text-align: center;
}

.successPage>button {
    width: 180px;
    margin: 0px auto;
}

.successAvatar {
    height: 160px;
    width: 160px;
    position: relative;
    margin: 0px auto;
    margin-bottom: 24px;
}

.successAvatarIcon {
    position: absolute;
    top: -20px;
    right: -20px;
    padding: 8px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: #fff;
    z-index: 20;
}

.successAvatarIcon>img {
    color: #fff;
}

.successAvatarImage {
    width: 100%;
    height: 100%;
    border-radius: var(--corner-radius);
}

.acceptButton {
    margin-top: 12px !important;
}

.session_info {
    text-align: center;
}

.successContent {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 24px;
    background-color: #1c1c1c;
    border-radius: 8px;
    padding-top: 32px;
    gap: 18px;
}

@media (min-width: 900px) {
    .successPage {
        max-width: 480px;
    }

    .successPage>h2 {
        margin-bottom: 12px;
    }
}
.stat {
    display: flex;
    justify-content: space-between;
    width: fit-content;
}

.lineItemIcon {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    width: 16px;
    height: 16px;
    margin-left: 8px;
    margin-top: 4px;
    cursor: pointer;
}

.lineItemTitle {
    min-width: 180px;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
}

.lineItem {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--black);
    padding: 12px 0px;
}

.helpContainer {
    position: relative;
}

.helpContainer>img {
    margin-left: 4px;
    margin-top: -6px;
}

.helpContainer>img:hover+.toolTip {
    display: inline;
}

.toolTip {
    display: none;
    color: #000;
    background-color: #f4f4f4;
    padding: 4px 8px;
    border-radius: 8px;
    min-width: 180px;
    position: absolute;
    left: 24px;
    font-size: 12px;
}
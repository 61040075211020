.header {
  width: 100%;
}

.page_container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin: 72px;
  width: 100%;
}

.filters_wrapper {
  width: 280px;
  position: none;
}

.filter_container {
  display: flex;
  width: 280px;
  margin-top: 34px;
  padding: 18px;
  background-color: var(--module-bg-dark);
  flex-direction: column;
  border-radius: 11px;
  position: fixed;
}

.mentor_container {
  position: relative;
  height: fit-content;
  width: 100vw;
  justify-content: center;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.mentor_collections {
  margin-top: 140px;
  display: block;
}

.clearButton {
  margin-top: 8px;
}

.mobile_search {
  width: 100%;
  padding: 12px;
  display: inline;
  position: fixed;
  z-index: 2;
  top: 72px;
  background-color: #000;
  border-bottom: 2px solid var(--module-bg-dark);
}

.desktop_search {
  display: none;
}

.no_results {
  margin: auto;
  margin-top: 10%;
}

.loading_spinner_container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 48px;
}

@media all and (min-width: 900px) {
  .mentor_collections {
    padding: 16px 16px 0px 16px;
    display: block;
    margin-top: 0px;
  }

  .filters_wrapper {
    display: block;
  }
  .mentor_container {
    padding: 0px;
    margin-bottom: 24px;
    width: 100%;
    justify-content: start;
  }
  .mobile_search {
    display: none;
  }
  .desktop_search {
    position: relative;
    display: block;
    width: 656px;
    margin: auto;
  }
  .header > h3 {
    position: absolute;
    margin-top: 4px;
  }
}

@media all and (max-width: 480px) {
  .page_container {
    margin: 0px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
}

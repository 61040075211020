.container {
    width: 100%;
    padding: 24px;
    background-color: var(--module-bg-dark);
    border-radius: var(--corner-radius);
    margin-top: 18px;
    margin-bottom: 24px;
}

.container>h4 {
    margin-bottom: 8px;
}

.availability {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.slot {
    padding: 12px 8px;
    border-radius: var(--corner-radius);
    border: 1px solid var(--body-text-on-black);
    color: var(--body-text-on-black);
    width: 46%;
    text-align: center;
    margin: 12px 12px 0px 0px;
    cursor: pointer;
}

.slot:hover {
    border: 1px solid var(--primary-colour);
}

.slotActive {
    border: 1px solid var(--primary-colour) !important;
    background-image: none;
    color: #fff;
}

.slotPreferred {
    border: 1px solid var(--primary-colour) !important;
    background-color: var(--primary-colour);
    background-image: none;
    color: #fff;
}


.noSlotsContainer {
    min-width: 496px;
    min-height: 126px;
    background-color: #000;
    border-radius: var(--corner-radius);
    padding-top: 32px;
}

.calIcon {}

.clear_select_button {
    color: #fff;
    text-align: right;
    font-family: "GT America";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    cursor: pointer;
}

.select_clear_container {
    display: flex;
    gap: 8px;
    width: 100%;
    justify-content: end;
    margin-top: 16px;
    padding-right: 8px;
}

.clear_select_button:hover {
    text-decoration: underline;
    color: #5440f0;
}

.legend {
    display: flex;
    gap: 8px;
    justify-content: start;
    margin-top: 16px;
    padding-right: 8px;
}

.legend_container {
    display: flex;
    gap: 8px;
    justify-content: end;
    margin-top: 16px;
    padding-right: 8px;
}

.legend_container>div {
    height: 16px;
    width: 16px;
    border-radius: 3px;
}

.legend_container>p {
    color: #AEAEAE;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "GT America";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    margin-top: -3px;
}

@media (max-width: 900px) {
    .slot {
        width: 100%;
    }

    .noSlotsContainer {
        min-width: 100%;
        min-height: 126px;
    }
}
.container {
  width: 100%;
  display: flex;
  width: 582px;
  padding: 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  border-radius: 8px;
  background: #1a1c1d;
}

.mentor {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.mentor_info {
  height: 100%;
  justify-content: space-between;
}

.details_container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 6px;
  width: 100%;
}

.details_container > svg {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}

.main_content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.container > button {
  display: flex;
  padding: 20px 25px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  border-radius: 3px;
  background: #5440f0;

  /* shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

@media all and (min-width: 900px) {
  .container {
  }
}

@media all and (min-width: 1320px) {
  .container {
  }
}

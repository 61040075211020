.container {
    overflow-y: scroll;
    display: block !important;
    max-height: 100vh;
	background-color: #1a1c1d;
}

.info {
    border-bottom: 1px solid #25282A;
    padding: 8px 0px 18px;
    font-size: 14px;
}

.form {
    padding: 12px 0px;
}

.submit {
    margin-top: 12px;
}

.error {
    color: #ff0000;
    font-size: 12px;
    margin-bottom: 8px;
    width: fit-content;
    padding-left: 12px;
    padding-right: 12px;
}

.attachments {
    margin-top: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

.attachment {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px 12px;
    width: fit-content;
}

.attachment>span {
    color: #fff;
    margin-top: 1px;
}

.attachment>img {
    opacity: 0.5;
}

.attachment>img:hover {
    opacity: 1;
}

@media all and (min-width: 900px) {
    .container {
        overflow-y: scroll;
        display: block !important;
        max-height: calc(100vh - 78px);
    }
}

@media all and (min-width: 1320px) {
    .container {}
}
:root {
  --primary-colour: #5440f0;
  --primary-colour-hover: #4837cf;
  --primary-colour-presses: #36299c;
  --pills-bg-colour: #ddd9fc;
  --error-colour: #fb836f;
  --error-colour-dark: #ff644d;
  --error-red: #ff8571;
  --black: #000;
  --module-bg-dark: #1a1c1d;
  --module-bg-colour: #25282a;
  --body-text-on-white: #66686a;
  --body-text-on-black: #bfbfbf;
  --disabled-colour: #bfbfbf;
  --field-outline-colour: #e9e9ea;
  --section-bg-colour: #f4f4f4;
  --corner-radius: 3px;
  --bold-font: "GT-America-Extended-Bold";
  --regular-font: "GT-America-Standard";
}

/* DEBUGGIN */
/* * {
    outline: 1px solid red;
   } */

::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  /* width: 3px;
    background: #FF0000; */
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
}

/* HACKS TO FIX 3rd party style  */
.fsp-button--primary {
  color: rgba(255, 255, 255, 0.9) !important;
  background-color: #2e68fb;
}
.MuiAlert-message {
  color: #000 !important;
}

body,
html {
  background: #000;
  scroll-behavior: smooth;
  position: relative;
  height: 100%;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  /* position: relative; */
  /* Fall back min-height  */
  min-height: 100%;
  min-height: calc(100% - 78px);
}

@media (max-width: 900px) {
  .App {
    min-height: 100%;
    min-height: 100vh;
  }
}

.center {
  display: block;
  margin: auto;
}

.popup-dropdown {
  display: none;
}

.page {
  position: relative;
  width: 100%;
}

/* Fonts */

@font-face {
  font-family: "GT-America-Standard";
  src: url("../fonts/GT-America/GT-America-Standard-Regular.woff")
      format("woff"),
    url("../fonts/GT-America/GT-America-Standard-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT-America-Extended-Bold";
  src: url("../fonts/GT-America/GT-America-Extended-Bold.woff") format("woff"),
    url("../fonts/GT-America/GT-America-Extended-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* STYLE GUIDE */

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-family: "GT-America-Extended-Bold";
  font-weight: normal;
  font-style: normal;
  color: #fff;
  text-align: left;
  -webkit-margin-before: 0px;
  margin-block-start: 0px;
  -webkit-margin-after: 0px;
  margin-block-end: 0px;
  /*	-webkit-touch-callout: none; /* iOS Safari */
  /*	-webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  /*	-moz-user-select: none; /* Old versions of Firefox */
  /*	-ms-user-select: none; /* Internet Explorer/Edge */
  /*	user-select: none; /* Non-prefixed version, currently */
}

h1 {
  font-size: 42px;
  line-height: 120%;
}

h2 {
  font-size: 36px;
  line-height: 120%;
}

h3 {
  font-size: 24px;
  line-height: 140%;
}

h4 {
  font-size: 16px;
  line-height: 140%;
}

label {
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  font-family: "GT-America-Extended-Bold";
}

@media (max-width: 900px) {
  h1 {
    font-size: 30px;
    line-height: 140%;
  }

  h2 {
    font-size: 24px;
    line-height: 140%;
  }

  h3 {
    font-size: 21px;
    line-height: 140%;
  }

  h4 {
    font-size: 14px;
    line-height: 140%;
  }

  label {
    font-size: 12px;
    line-height: 14px;
  }

  p {
    font-size: 14px;
    line-height: 17px;
  }
}

label.black {
  font-family: "GT-America-Extended-Bold";
  font-weight: normal;
  font-style: normal;
  color: #fff;
  text-align: left;
}

label.error {
  color: var(--error-colour);
}

input.error {
  border: 1px var(--error-colour) solid !important;
}

textarea.error {
  border: 1px var(--error-colour) solid !important;
}

a {
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: var(--body-text-on-white);
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--primary-colour-hover);
}

p {
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: var(--body-text-on-black);
  text-align: left;
  -webkit-margin-before: 0px;
  margin-block-start: 0px;
  -webkit-margin-after: 0px;
  margin-block-end: 0px;
  /*	-webkit-touch-callout: none; /* iOS Safari */
  /*	-webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  /*	-moz-user-select: none; /* Old versions of Firefox */
  /*	-ms-user-select: none; /* Internet Explorer/Edge */
  /*	user-select: none; /* Non-prefixed version, currently */
}

.onwhite {
  color: var(--body-text-on-white);
}

li {
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: var(--body-text-on-black);
  text-align: left;
}

li:hover {
  color: var(--body-text-on-white);
}

@media all and (max-width: 900px) {
  /* HERE */
  /* p {
        margin-bottom: 12px;
    } */
}

span {
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  color: var(--body-text-on-white);
}

/* $$$$$$$$$ BUTTONS $$$$$$$$$$$ */

button {
  background-color: var(--primary-colour);
  -webkit-box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.25);
  border-radius: var(--corner-radius);
  font-family: "GT-America-Extended-Bold";
  color: #fff;
  border: none;
  /* Desktop */
  font-size: 12px;
  line-height: 14px;
  height: 42px;
  padding: 12px 20px;
  cursor: pointer;
}

/* Mobile Button*/
@media all and (max-width: 900px) {
  button {
    font-size: 12px;
    width: 100%;
  }
}

button:hover {
  background-color: var(--primary-colour-hover);
}

button:active {
  background-color: var(--primary-colour-presses);
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
}

button:disabled {
  background-color: var(--disabled-colour);
  -webkit-box-shadow: none;
  box-shadow: none;
}

button.btn-small {
  font-size: 12px;
  height: 32px;
  line-height: 15px;
  padding: 8px 24px;

  @media all and (max-width: 900px) {
    font-size: 10px;
  }
}

button.tiny-button {
  width: 32px;
  height: 32px;
  background: none;
  border: none;
}

button.btn-dark {
  border: 1px solid #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: var(--black);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  box-shadow: none;
  border-radius: var(--corner-radius);
}

button.btn-dark:hover {
  background: #66686a;
  border: 1px solid #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: var(--corner-radius);
}

button.btn-dark:active {
  background: rgba(255, 255, 255, 0.9);
  color: #000;
}

button.btn-dark:disabled {
  background-color: var(--module-bg-dark) !important;
  color: var(--disabled-colour);
  border: 1px solid var(--disabled-colour) !important;
  opacity: 0.6;
}

button.btn-red {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: var(--error-colour);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}

button.btn-red:hover {
  background-color: var(--error-colour);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: var(--corner-radius);
  color: #fff;
}

button.btn-red:active {
  background-color: var(--error-colour);
  opacity: 0.9;
  color: #fff;
}

button.btn-red:hover {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: var(--error-colour-dark);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}

button.btn-red:disabled {
  background-color: var(--module-bg-dark) !important;
  color: var(--disabled-colour);
  border: 1px solid var(--disabled-colour) !important;
  opacity: 0.6;
}

@media all and (max-width: 900px) {
  button {
    font-size: 12px;
    height: 32px;
    line-height: 15px;
    padding: 8px 24px;
  }
}

/* Notifications */
.notification-dot {
  height: 8px;
  width: 8px;
  background-color: var(--error-colour);
  border-radius: 50%;
  display: inline-block;
  margin: 0px 2px 7px;
}

.notification-bar {
  height: 30px;
  background-color: var(--primary-colour);
  color: var(--section-bg-colour);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0px;
  left: 0px;
  border-bottom: 2px solid var(--module-bg-dark);
}

.notification-bar > span {
  background-color: var(--section-bg-colour);
  color: var(--primary-colour);
  padding: 2px 12px;
  border-radius: 24px;
  position: relative;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "GT-America-Extended-Bold";
  line-height: 18px;
  height: 20px;
  margin: 4px;
}

.notification-bar > p {
  margin-top: 6px;
  margin-left: 4px;
  font-size: 14px;
  color: var(--section-bg-colour);
}

.notification-bar > button {
  background-color: var(--section-bg-colour);
  color: var(--primary-colour);
  padding: 2px 12px;
  border-radius: 24px;
  position: relative;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "GT-America-Extended-Bold";
  line-height: 18px;
  height: 20px;
  margin: 4px 6px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.mobile-notification > .content-container > .mobile-header {
  margin-top: 54px;
}

@media all and (max-width: 900px) {
  .notification-bar {
    height: 54px;
  }

  .notification-bar > p {
    margin: 12px 5px;
    font-size: 12px;
  }

  .notification-bar > span {
    margin: 17px 8px;
  }
  .mobile-notification {
    margin-top: 54px;
  }
}

/* $$$ FORM $$$ */
form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

form > input {
  margin-bottom: 8px;
}

input + label {
  text-align: left;
  float: left;
  position: relative;
  top: -68px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

input + label > p {
  margin-left: 12px;
  font-size: 12px;
}

textarea + label {
  text-align: left;
  position: relative;
  top: -140px;
}

/* $$$$$$$$$$ INPUTS $$$$$$$$$$ */

input,
textarea {
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

textarea {
  height: 120px;
  resize: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid var(--field-outline-colour);
  background: #ffffff;
  border-radius: var(--corner-radius);
  padding: 12px 0px 13px 20px;
  width: 100%;
  height: 42px;
  margin-top: 12px;
}

input[type="text"].black,
input[type="email"].black,
input[type="password"].black,
input[type="number"].black,
textarea.black {
  color: var(--body-text-on-black);
  background-color: var(--black);
  border: none;
}

input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #bfbfbf;
}

input[type="text"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
textarea::-moz-placeholder {
  color: #bfbfbf;
}

input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #bfbfbf;
}

input[type="text"]::-ms-input-placeholder,
input[type="email"]::-ms-input-placeholder,
input[type="password"]::-ms-input-placeholder,
input[type="number"]::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #bfbfbf;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"]::placeholder,
textarea::placeholder {
  color: #bfbfbf;
}

/* TEXT INPUT FOCUS */
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
textarea:focus,
input:-webkit-autofill:focus {
  border: 1px solid var(--primary-colour);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  outline-offset: 0;
}

input[type="text"]:active,
input[type="email"]:active,
input[type="password"]:active,
input[type="number"]:active,
textarea:focus,
input:-webkit-autofill:active {
  border: 1px solid var(--primary-colour);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

input[type="text"]:focus + label,
input[type="email"]:focus + label,
input[type="password"]:focus + label,
input[type="number"]:focus + label,
textarea:focus + label {
  color: var(--primary-colour);
}

/* TEXT INPUT INVALID */
input[type="text"]:invalid,
input[type="email"]:invalid,
input[type="password"]:invalid,
input[type="number"]:invalid {
  border: 1px solid var(--error-colour);
}

input[type="text"]:invalid + label,
input[type="email"]:invalid + label,
input[type="password"]:invalid + label,
input[type="number"]:invalid + label {
  border: 1px solid var(--error-colour);
}

input:-internal-autofill-selected {
  background-color: #fff;
}

/* Required */
.required {
  color: var(--error-colour);
}

input[type="radio"] {
}

/* MOBILE INPUTS */
@media all and (max-width: 900px) {
  input {
    font-size: 12px;
    height: 32px;
    line-height: 15px;
  }

  input + label {
    top: -56px;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    margin-top: 12px;
    height: 32px;
  }

  .MuiTypography-body1 {
    font-size: 14px !important;
    line-height: 17px !important;
  }
}

/* $$$ TEXTAREA $$$ */
textarea {
}

iframe {
  border-radius: 20px;
}

/* $$$$$$$$ MOBILE $$$$$$$$$$ */

.mobile-only {
  display: none;
}

.hide-flex {
  display: none !important;
}

@media all and (max-width: 900px) {
  .mobile-only {
    display: inline;
  }
  .hide-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .desktop-only {
    display: none;
  }
}

/* Calendly iFrame */
.calendly {
  width: 420px;
  height: 905px;
}

.cal-button {
  width: "100%";
  position: relative;
}

@media all and (max-width: 900px) {
  .calendly {
    width: 320px;
    height: 1300px;
  }

  .cal-button {
    top: -10px;
    margin-bottom: 12px;
  }
}

/* TOP MENU */
.nav-link {
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding-top: 13px;
  color: #6e6e6e;
}

.nav-link:hover {
  color: #fff;
}

.mobile-nav-link {
  font-family: "GT-America-Extended-Bold";
  font-size: 24px;
  line-height: 240%;
  color: var(--body-text-on-black);
  position: relative;
}

.mobile-nav-link:hover {
  color: #fff;
  /* position: absolute; */
}

.open-mobile-nav {
  -webkit-transform: translateX(100vw);
  -ms-transform: translateX(100vw);
  transform: translateX(100vw);
  position: relative;
}

/* Modal */
.modal-container {
  position: fixed;
  /* Stay in place */
  z-index: 200;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5);
  /* Black w/ opacity */
}

.modal-content {
  -webkit-box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: var(--corner-radius);
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 12px;
}

.modal-content-stripe-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-top: 24px;
}

.modal-content-stripe-buttons > div {
  padding: 38px 0 14px;
}
.modal-content-stripe-buttons > button {
  margin: 38px 0px 14px;
}

@media all and (min-width: 900px) {
  .modal-content {
    padding: 24px;
    max-width: 720px;
    width: fit-content;
  }
}

@media all and (max-width: 900px) {
  .modal-content-stripe-buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .modal-content-stripe-buttons > button {
    margin: auto;
  }

  .modal-content > div > button {
    width: 48%;
  }
  .content-container-profile {
    padding: 0px;
    margin-top: 54px;
    /* margin: 235px 0px 32px 0px; */
  }
}

.modal-close {
  position: absolute;
  top: 12px;
  right: 12px;
  height: 24px;
  width: 24px;
}

.modal-content > video {
  width: 520px;
  margin: 24px;
}

/* Waiting List */
.waiting-list {
  margin: auto;
  max-width: 820px;
  margin-top: 200px;
}

.waiting-list > p {
  margin: 24px 0px 24px 0px;
}

@media all and (max-width: 900px) {
  .waiting-list {
    padding: 24px;
    margin-top: 52px;
  }
}

/* Modal - StripeMentor */

/* $$ TOP SUB MENU  $$*/
.top-sub-menu {
  border-bottom: 1px solid var(--body-text-on-white);
}

.top-sub-menu > h1 {
  color: #fff;
}

.top-sub-menu > ul {
  list-style: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  /* width: 520px; */
  float: left;
  padding: 0px;
  position: relative;
}

.top-sub-menu > ul > li {
  font-size: 18px;
  line-height: 140%;
  padding-right: 18px;
  color: var(--body-text-on-white);
  font-family: "GT-America-Extended-Bold";
  cursor: pointer;
}

.top-sub-menu > ul > li:hover {
  color: var(--body-text-on-black);
}

.sub-menu-item-active {
  color: #fff !important;
  /* text-decoration: underline */
}

/* $$ ACCOUNT $$ */
.section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section:last-child {
  padding-bottom: 30px;
}

.section-content {
  width: 90%;
  padding-top: 24px;
  border-radius: var(--corner-radius);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media all and (max-width: 900px) {
  .section-content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.dropdown-dark {
  width: 130px;
  margin-top: 0px !important;
}

.dropdown-dark label {
  display: none;
}

.account-edit-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 8px;
}

/* EDIT PILL */
.edit-pill-value {
  float: left;
  padding: 12px;
  border-radius: var(--corner-radius);
  background-color: var(--field-outline-colour);
  color: var(--module-bg-colour) !important;
}

/* Application Browser */
#application-browser {
  height: 100%;
  width: 100%;
}

.application-modal-container {
  margin: auto;
  margin-top: 10%;
  height: 540px;
  width: 540px;
  background: #ffff;
  padding: 60px;
  border-radius: var(--corner-radius);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 0 black;
  box-shadow: 0 0 black;
  position: relative;
}

@media all and (max-width: 900px) {
  .application-modal-container {
    width: 100vw !important;
    padding: 24px 32px !important;
  }
}

/* ACCOUNT SETTINGS */

/* Profile Selector */
#profile-select-container {
  position: relative;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  float: right;
}

#profile-select-container > button {
  padding: 11px 18px;
}

#top-content {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
}

#profile-media-container {
  position: relative;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: right;
}

#profile-image-picker {
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 8px;
  background-color: var(--pills-bg-colour);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#profile-video-picker {
  width: 356px;
  height: 202px;
  position: relative;
  border-radius: 8px;
  background-color: #000;
  display: inline;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid var(--body-text-on-black);
}

#profile-image-picker > img {
  border-radius: 8px;
}

#profile-video-picker > video {
  border-radius: 8px;
}

.video-control-button {
  position: absolute;
  z-index: 5;
  right: 118px;
  top: 80px;
  width: 42px;
  height: 42px;
  cursor: pointer;
}

@media all and (max-width: 900px) {
  #top-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    margin-bottom: 0px;
  }
  #profile-media-container {
    width: 100%;
    margin-bottom: 0px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #profile-media-container > div {
    margin-bottom: 32px;
    display: -ms-inline-grid;
    display: inline-grid;
  }
  #profile-select-container {
    width: 100%;
    margin-bottom: 32px;
  }

  #profile-video-picker {
    width: 300px !important;
    width: calc(100vw - 24px) !important;
  }

  #profile-video-picker > video {
    border-radius: 8px;
    width: 300px !important;
    width: calc(100vw - 24px) !important;
  }
  .video-control-button {
    right: calc(50% - 21px);
  }
}

.video-label {
  float: left;
  /* margin-bottom: 10px; */
  position: relative;
  top: -10px;
}

.upload-icon-container {
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 0px;
  background-color: black;
  padding: 7px;
  right: 0px;
  border-radius: 8px 0px 8px 0px;
}

.upload-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.onhover {
  display: none;
}

/* CONTENT CONTAINER */
.content-container {
  position: relative;
  margin-left: 72px;
  height: 100vh;
  width: 100%;
  padding: 25px 60px;
  display: block;
  margin-bottom: 24px;
}

@media all and (max-width: 900px) {
  .content-container {
    margin: 0px;
    padding: 72px 6px 0px 6px;
  }
}

.content-container-profile {
  width: 100%;
  margin: auto;
  position: relative;
  /*   height: 100vh;
    padding: 45px 60px;
    display: flex;
    flex-direction: column;
    margin-top: 200px;
    margin-left: 72px; */
}

.divider {
  width: 100%;
  border-bottom: 2px solid #fff;
  opacity: 0.6;
  margin-bottom: 32px;
}

/* HIGHLIGHTS */
#new-highlight-button {
  text-align: center;
  border-radius: var(--corner-radius);
  border: 1px #fff solid;
  padding: 10px 0px;
  cursor: pointer;
}

#new-highlight-button:hover {
  color: var(--primary-colour);
  border: 1px var(--primary-colour) solid;
}

.new-highlight {
  border-radius: var(--corner-radius);
  border: 1px solid #fff;
  padding: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
  /* max-width: 552px; */
}

.open-new-content-container {
  border-radius: var(--corner-radius);
  text-align: center;
  border: solid 1px #ffff;
  padding: 24px;
  max-width: 620px;
}

.highlight-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  min-height: 112px;
  border: 1px solid var(--field-outline-colour);
  padding: 12px;
  /* margin-top: 12px; */
  border-radius: var(--corner-radius);
}

/* .highlight-container > div {
    display: flex;
    flex-direction: row;
    position: relative;
} */

.highlight-btn-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.highlight-container > h3 {
  margin-left: 8px;
  margin-top: 10px;
}

/* RIGHT SIDEBAR */
.right-side-bar {
  background-color: var(--module-bg-dark);
  color: var(--section-bg-colour);
  padding: 36px 35px 0px 35px;
  position: fixed;
  height: 100%;
  right: 0px;
  min-width: 312px;
  max-width: 312px;
}

.mentorpass-card-info-mentor {
  text-align: left;
  text-transform: uppercase;
  margin-left: 28px;
  margin-top: -12px;
}

.mentorpass-card-info-mentor > p {
  font-size: 10px;
  margin-top: 5px;
  width: 200px;
}

.mentorpass-card-info-mentee > * {
  text-align: center;
}

@media all and (max-width: 900px) {
  .right-side-bar {
    display: none;
  }
  .highlight-container {
    width: 100%;
  }
  .open-new-content-container {
    max-width: 100%;
  }
  .new-highlight {
    padding: 12px 8px;
    margin-bottom: 24px;
    margin-top: 24px;
    /* max-width: 552px; */
  }
  .highlight-btn-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .highlight-btn-container > button {
    width: 100%;
    margin-top: 12px;
  }
}

.right-side-bar > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* .right-side-bar > div > img {
    margin-left: 32px;
} */
.mini-avatar {
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: 32px;
  right: 16px;
  border: 3px var(--primary-colour-presses) solid;
  border-radius: 50%;
}

.mentorpass-card-info {
  position: absolute;
  top: 60px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.mentorpass-card-info > * {
  width: 100%;
  text-align: center;
}

.right-side-bar > div > h4 {
  margin-top: 9px;
  margin-left: 18px;
}

#referal-deal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: var(--corner-radius);
  background: #fff;
  padding: 8px 18px;
  color: #000;
}

#mentorpass-card {
  width: 100%;
  margin-bottom: 12px;
}

.account-column {
  width: 46%;
  margin-right: 4%;
  margin-top: 8px;
}

.account-column > form {
  max-width: 360px;
}

@media all and (max-width: 900px) {
  .account-column {
    width: 100%;
    margin-bottom: 32px;
  }
  .account-column > form {
    max-width: 100%;
  }
}

.account-avatar-img {
  max-width: 200px;
  height: auto;
  overflow: hidden;
  border-radius: var(--corner-radius);
}

/* PROFILE PAGE */

.profile-container {
  padding: 70px;
  padding-top: 0px;
  border-radius: var(--corner-radius);
  position: relative;
}

.profile-avatar-img {
  width: 324px;
  min-height: 324px;
  max-height: 324px;
  border-radius: var(--corner-radius);
  position: relative;
  margin-right: 32px;
  overflow: hidden;
  border-radius: var(--corner-radius);
}

.avatar-mp-cube {
  position: absolute;
  bottom: 0px;
  right: 32px;
  height: 80px;
  width: 80px;
  padding: 28px 16px;
  background-color: #000;
  border-radius: var(--corner-radius) 0px 3px 0px;
}

.profile-header {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: left;
  /* display: flex;
  width: 100%;
  flex-direction: row; */
}

.profile-header-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  float: left;
  min-height: 324px;
  height: auto;
  width: 100%;
  /* width: auto; */
}

.profile-header-content > h3 {
  margin-bottom: 3px;
  color: var(--body-text-on-black);
}

/* .profile-header-content > p {
  font-size: 14px;
} */

.session-counter {
  /* position: absolute; */
  top: 0px;
  right: 0px;
  background-color: var(--black);
  border-radius: 39px;
  height: 34px;
  color: var(--body-text-on-black);
  padding: 3px 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.session-counter > p {
  margin: -2px 8px 0px 8px;
}

.session-counter > p > span {
  top: 0px;
  font-size: 14px;
  position: relative;
  font-family: "GT-America-Extended-Bold";
  color: var(--field-outline-colour);
}

.headline {
  font-size: 16px !important;
  margin-bottom: 12px;
}

.profile-action-btns {
  /* position: absolute; */
  bottom: 0px;
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.profile-video-preview {
  height: 200px;
  width: 100%;
  position: relative;
  /* margin: 68px -70px 84px -70px; */
}

.profile-video-preview > div {
  position: absolute;
  /*  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px; */
  height: 100%;
  width: 100%;
  border-radius: var(--corner-radius);
}

.profile-video-preview > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-video-preview > span {
  /* font-family: "GT-America-Extended-Bold";
    font-size: 24px;
    line-height: 240%;
    color: var(--body-text-on-black);
    */
  position: relative;
  top: -130px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.profile-video-preview > span > img {
  margin-bottom: 4px;
  margin-left: 12px;
}

.skills-pill-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-top: 40px; */
  /* width: 100%;
  margin-top: 12px;
  position: absolute;
  bottom: 62px;
  margin-bottom: -8px; */
}

.pill {
  height: 28px;
  padding: 2px 15px;
  border: 1px solid var(--body-text-on-white);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 32px;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: max-content;
  color: var(--body-text-on-white);
  margin-right: 12px;
  margin-bottom: 12px;
}

.profile-about-section {
  /* display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap; */
  background-color: var(--module-bg-dark);
  margin-top: 25px;
  padding: 25px;
  /* width: 49%; */
  -webkit-box-flex: 0;
  -ms-flex: 0 100%;
  flex: 0 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: var(--corner-radius);
}

.profile-about-content {
  width: 100%;
}

.profile-about-content > ul {
  padding: 0px;
  list-style: none;
  margin-top: 24px;
}

.about-content-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}

.about-content-item > div > img {
  float: left;
  height: 32px;
  width: 32px;
  margin-right: 16px;
}

.about-content-item > div > h5 {
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 12px;
}

.about-content-item > div > p {
  font-size: 14px;
  color: var(--body-text-on-black);
  margin-bottom: 12px;
}

.about-content-item > div > span {
  font-size: 12px;
  color: var(--body-text-on-white);
  left: 0px;
  position: relative;
  margin-left: 64px;
}

.title-letter {
  width: 32px;
  height: 32px;
  border: 2px solid var(--body-text-on-black);
  border-radius: 50%;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin-right: 16px;
}

.title-letter > p {
  margin: auto;
  width: 100%;
  text-align: center;
  margin-top: 5px;
}

.share-profile {
  height: 220px;
  width: 100%;
  /* background-image: url("../images/mp-background.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--module-bg-dark);
  margin-top: 52px;
  border-radius: var(--corner-radius);
  margin-bottom: 72px;
}

.share-profile > div {
  margin: auto;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 42px;
  height: 220px;
}
.share-profile > div > h3 {
  margin-bottom: 8px;
  text-align: center;
}

.share-profile > div > button {
  background: none;
}

.share-profile > div > button:hover {
  background: #fff;
}

.profile-preview-edit {
  position: absolute;
  top: 18px;
  left: 18px;
  padding: 6px 12px;
  border-radius: var(--corner-radius);
  background-color: var(--black);
  color: var(--body-text-on-black);
  font-size: 14px;
}

.profile-preview-edit:hover {
  color: #fff;
  border: 1px #fff solid;
}

.profile-preview-edit > img {
  height: 18px;
  position: relative;
}

.password-input {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 25px;
}

.input-container {
  position: relative;
  margin-bottom: 8px;
}

.input-container-checkbox {
  margin: 12px 0px;
  max-width: 360px;
}

.input-container-checkbox > input {
  width: 18px !important;
  height: 18px !important;
  float: right;
}

.input-container > input {
  font-family: "GT-America-Extended-Bold";
  font-size: 12px;
  line-height: 14px;
}

.input-container > input {
  margin-top: 7px;
  padding-left: 20px;
  font-family: "GT-America-Standard";
  font-size: 14px;
  line-height: 17px;
  height: 42px;
}

.availability-days-container {
  margin-bottom: 64px;
  max-width: 980px;
}

.availability-days {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#avalible-hours-label {
  float: left;
  margin-bottom: 12px;
}
.availability-day {
  background-color: var(--module-bg-dark);
  min-width: 108px;
  max-width: 120px;
  margin-right: 12px;
  padding: 8px 12px;
  min-height: 98px;
  cursor: pointer;
}

.availability-day > div > p {
  font-size: 12px;
  margin: 6px 0px;
}

.help-text {
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: var(--body-text-on-black);
  text-align: left;
  float: left;
  margin: 6px 0px;
  padding-right: 75px;
}

.available-times {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-bottom: 140px;
}

.available-times > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.available-times > div > p {
  padding-top: 2px;
  color: var(--body-text-on-white);
  width: 180px;
  margin: 16px 0px;
}

.available-times > div > img:hover {
  border-radius: var(--corner-radius);
  border: 2px solid var(--disabled-colour);
  height: 24px;
  width: 24px;
  padding: 1px;
  margin: 1em 0;
}

.add-time {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.add-time > div > button {
  width: 48%;
}

.add-time > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.set-hours-content {
  height: 100%;
  position: relative;
}

.set-hours-content > div > label {
  float: left;
  margin-top: 24px;
  margin-right: 28px;
}

.set-hours-content > button {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
}

/* .profile-action-btns-mid {
  display: none;
} */

.profile-preview-pitch {
  padding: 0px 60px !important;
  font-size: 30px;
}

@media all and (max-width: 1300px) {
  .profile-preview-pitch {
    padding: 0px 12px;
    font-size: 16px;
  }
  .profile-avatar-img {
    width: 240px;
    max-height: 240px;
    min-height: 240px;
  }

  /* .profile-action-btns {
    display: none;
  } */

  /* .profile-action-btns-mid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
    padding-top: 8px;
  }

  .profile-action-btns-mid > button {
    width: 240px;
  } */

  .skills-pill-container {
    bottom: 0px;
  }

  .profile-header-content > p {
    font-size: 12px;
    line-height: 16px;
    margin-top: 20px;
  }

  .avatar-mp-cube {
    top: 160px;
  }
}

@media all and (max-width: 900px) {
  /* .profile-action-btns-mid {
    display: none;
  } */

  .profile-header-content > p {
    font-size: 14px;
    line-height: 18px;
  }
}

/* EXPERIENCE / SKILLS / ARTICLES */
.profile-edit-container {
  position: relative;
  width: 100%;
  padding-top: 24px;
}

.profile-edit-container > p {
  height: 60px;
  margin-bottom: 6px;
}

.availability-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.profile-faux-bg {
  position: fixed;
  top: 0px;
  background-color: #5440f0;
  width: calc(100% - 385px);
  height: 310px;
  /* background-image: url(/static/media/mp-background.ed9d8d3e.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  margin-left: 73px;
}

.profile-faux-bg > h2 {
  width: 100%;
  text-align: center;
  margin-top: 90px;
}

.row-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* MOBILE MENU */
.mobile-menu {
  position: fixed;
  display: none;
  left: -100vw;
  z-index: 50;
  height: 100%;
  width: 100vw;
  background-color: var(--module-bg-dark);
  -webkit-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  transition: all ease 0.4s;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.mobile-menu.mobile-open {
  left: 0vw;
}

.profile-avatar {
  position: relative;
}

@media all and (max-width: 900px) {
  .mobile-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .menu-link-text {
    display: inline;
    opacity: 1;
    visibility: initial;
  }
  .row-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .row-container > div {
    width: 100% !important;
  }
  .availability-days {
    overflow-x: auto;
  }
  .availability-day {
    min-width: 36%;
  }
  .profile-edit-container {
    width: 100%;
    margin-bottom: 24px;
  }
  .pill {
    padding: 4px 15px;
  }
  .edit-pill {
    height: 27px;
  }
  .add-skill {
    height: 27px;
    width: 27px;
  }
  .title-letter > p {
    margin-top: 7px;
  }
  .profile-faux-bg {
    margin: 0px;
    width: 100%;
    height: 165px;
    margin-top: 72px;
  }
  .profile-faux-bg > h2 {
    margin-top: 42px;
    padding: 0px 24px;
  }
  .profile-container {
    padding: 0px;
    border-radius: 0px;
  }
  .profile-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .profile-avatar {
    margin: auto;
    width: 100%;
    height: auto;
    position: relative;
  }
  .profile-avatar-img {
    width: 100%;
    max-height: 100%;
    min-height: auto;
    height: auto;
  }
  .avatar-mp-cube {
    display: none;
  }
  .profile-header-content {
    margin-top: 12px;
    height: 100%;
  }
  .session-counter {
    margin: 5px 0px;
  }
  .profile-action-btns {
    position: relative;
    margin-top: 12px;
  }
  .skills-pill-container {
    position: relative;
    bottom: 0px;
  }
  .profile-video-preview > span {
    top: -150px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .profile-video-preview > span > h3 {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 12px;
  }
  .profile-video-preview {
    /* margin: 32px -20px 32px -20px; */
  }
  .profile-about-content {
    width: 100%;
  }

  .modal-content > video {
    margin: 12px -24px 0px;
    width: 100vw;
  }
  .profile-preview-edit {
    top: -165px;
    left: 0px;
    padding: 7px 9px 4px 9px;
    border-radius: 0px 0px 4px 0px;
  }
}

#mobile-close-nav {
  position: absolute;
  right: 24px;
  margin-top: 3px;
}

.mobile-header {
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 72px;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  background-color: var(--black);
  z-index: 10;
  border-bottom: 2px solid var(--module-bg-dark);
}

.mobile-header > img {
  padding: 24px 0px 24px 24px;
}
.mobile-header > h3 {
  margin-top: 22px;
  margin-left: 24px;
}

.mobile-header > button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 18px 24px;
  right: 0px;
  position: fixed;
}

.mobile-header-nav {
  margin-top: 12px;
}

.mobile-header-nav > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  list-style: none;
  padding: 0px;
  margin: 0px -12px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: normal;
  -ms-flex-pack: normal;
  justify-content: normal;
  height: 60px;
  border-bottom: 2px solid var(--body-text-on-white);
}

.mobile-header-nav > ul > li {
  margin-left: 12px;
}

/* MASTER CONTAINER */
.master-container {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  margin-left: 72px;
}

@media all and (max-width: 900px) {
  .master-container {
    margin-right: 0px;
    margin-left: 0px;
  }
}
/* CONTENT CONTAINER */

.coming-soon-text {
  margin: 64px auto;
  text-align: center;
}

.copy-to-clipboard {
  width: 90%;
  margin: 18px auto;
}

.copy-to-clipboard > input {
  width: 100%;
}

.copy-to-clipboard > button {
  width: 100%;
  margin-bottom: 18px;
}

/* Pricing page */
.offering-container {
  width: 1258px;
  height: 486px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-evenly;
  margin: auto;
}
.pricing-tagline {
  font-family: GT-America-Standard;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #ddd9fc;
  opacity: 0.3;
  letter-spacing: 1px;
  float: right;
  padding-top: 6px;
}

.pricing-container {
  position: relative;
  width: 360px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #5440f0;
  padding: 32px 42px;
  min-height: 440px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pricing-price {
  font-family: GT-America-Extended-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #ffffff;
  float: left;
}

.pricing-period {
  font-family: GT-America-Extended-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #ffffff;
  opacity: 0.3;
  float: left;
  margin-left: 1px;
}

.pricing-offer-link {
  font-family: GT-America-Extended-Bold;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #fff;
  letter-spacing: 1px;
}

.little-arrow {
  height: 14px;
  width: 14px;
  margin-left: 10px;
  position: absolute;
  margin-top: 4px;
}

.pricing-page {
  width: 100%;
  height: 100%;
}

.pricing-page-container {
  width: auto;
  height: 1049px;
  background: #fff;
}

.pricing-tagling {
  font-family: var(--bold-font);
  font-size: 16px;
  line-height: 14px;
  color: var(--body-text-on-white);
  padding-top: 6px;
  text-align: left;
  float: left;
  margin-bottom: 10px;
}

.pricing-tagling > span {
  color: var(--primary-colour);
  font-family: var(--bold-font);
  font-size: 16px;
  line-height: 14px;
}

.pricing-page-top-content {
  width: 1258px;
  padding: 90px 40px 0px 40px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.pricing-page-top-copy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pricing-page-headline {
  font-family: GT-America-Extended-Bold;
  margin-bottom: 20px;
  color: #000;
  font-size: 44px;
  line-height: 120%;
  white-space: normal;
  color: #000000;
  text-align: left;
  float: left;
}

.pricing-page-subtitle {
  width: 541px;
  color: #66686a;
  font-size: 18px;
  line-height: 22px;
  color: #66686a;
  opacity: 0.9;
  text-align: left;
  float: left;
}

/* Pricing Toggle */
.pricing-tog-in-circle {
  width: 133.88px;
  height: 38px;
  background: #000000;
  border-radius: 28px;
  padding: 12px;
  color: #fff;
}

.pricing-tog-out-circle > div {
  cursor: pointer;
  -webkit-transition: background 200ms ease;
  -o-transition: background 200ms ease;
  transition: background 200ms ease;
}

.pricing-tog-out-circle {
  width: 305px;
  height: 63px;
  background: #f4f4f4;
  border-radius: 38px;
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mid-text {
  font-family: GT-America-Extended-Bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #fff;
}

.pricing-tog-out-circle > div {
  font-family: var(--bold-font);
  font-size: 16px;
  line-height: 17px;
  text-align: center;
}

.mid-text {
  margin-top: 2px;
}

@media all and (max-width: 900px) {
  .MuiAlert-message {
    text-align: left;
  }
}

.highlight-drag-icon {
  margin: 2px 4px 0px 0px;
}

#error-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 32px;
}

.register-success-text {
  text-align: center;
  max-width: 500px;
  margin: auto;
}

@media all and (max-width: 900px) {
  .register-success-text {
    max-width: 320px;
    padding: 12px;
  }
}

/* LOGIN */
.login-container {
  width: 100%;
  margin-top: 12px;
}

.login-button-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 18px;
}

.login-button {
  width: 30%;
  margin-left: auto;
}

@media all and (max-width: 900px) {
  .login-button-container {
    width: 100%;
  }
  .login-button-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 0px;
  }
  .login-button {
    width: 100%;
  }
}
/* Atoms */
/* PillDropDown.jsx */
.a-pdd {
  background-color: var(--black);
  color: var(--body-text-on-black);
  padding: 6px 15px;
  height: 32px;
  border-radius: 16px;
  width: -moz-fit-content;
  width: 108px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.open {
  border-radius: 16px 16px 0px 0px;
}

.a-pdd-dropdown {
  position: absolute;
  background-color: var(--black);
  color: var(--body-text-on-black);
  top: 32px;
  width: 108px;
  border-radius: 0px 0px 16px 16px;
  -webkit-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  z-index: 20;
}

.a-pdd-dropdown > div {
  width: 100%;
  border-top: 1px solid var(--module-bg-colour);
}

.a-pdd-dropdown > div:hover {
  background-color: var(--module-bg-colour);
}
.a-pdd-dropdown > div:last-child:hover {
  border-radius: 0px 0px 16px 16px;
}

.a-pdd-dropdown > div:hover > p {
  color: #fff;
}

.a-pdd-dropdown > div > p {
  text-align: center;
  font-size: 14px;
  padding: 6px 0px;
}

@media all and (max-width: 900px) {
  .a-pdd > p {
    line-height: 140%;
  }
}

/* Molecules */

/* Session Request Widget */
.m-srw {
  width: 100%;
  border-radius: var(--corner-radius);
  background-color: var(--module-bg-dark);
  padding: 24px;
  margin-bottom: 12px;
  border-bottom: 2px solid var(--black);
}

.m-srw-main {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.m-srw-main-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.m-srw-preview {
}

.m-srw-preview > div {
  margin-top: 14px;
}

.m-srw-details {
  padding-top: 24px;
}

.m-srw-details > div {
  margin-bottom: 18px;
  width: 100%;
}

.m-srw-details > div > a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-decoration: underline;
  color: var(--body-text-on-black);
}

.m-srw-details > div > h5 {
  color: var(--body-text-on-black);
  margin-bottom: 12px;
}

.m-srw-avatar {
  width: 120px;
  height: 120px;
  border-radius: var(--corner-radius);
  margin-right: 24px;
}

.m-srw-buttons {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.m-srw-buttons > button {
  margin-right: 18px;
}

/*  the little session info icon elements */
.m-srw-sessioninfo {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.m-srw-sessioninfo > img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.m-srw-sessioninfo > p {
  font-size: 12px;
}

.m-srw-pillopen-container {
  position: absolute;
  right: 84px;
}

@media all and (max-width: 900px) {
  .m-srw {
    padding: 12px;
    margin-bottom: 8px;
  }

  .m-srw-avatar {
    width: 60px;
    height: 60px;
    border-radius: var(--corner-radius);
    margin-right: 12px;
    position: absolute;
  }
  .m-srw-preview {
    width: 100%;
    /* display: inline-flex;
        justify-content: flex-start;
        flex-direction: column; */
  }
  .m-srw-preview > h3 {
    margin-left: 72px;
  }
  .m-srw-preview > p {
    margin-left: 72px;
  }
  .m-srw-preview > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
  }
  .m-srw-sessioninfo {
    padding-bottom: 8px;
    margin-top: 3px;
  }
  .m-srw-pillopen-container {
    position: relative;
    width: 100%;
    right: 0px;
    left: 0px;
  }
  .m-srw-pillopen-container > div {
    margin-top: 8px;
  }

  .m-srw-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .m-srw-buttons > button {
    margin-bottom: 12px;
  }

  .m-srw-buttons :last-child {
    margin-bottom: 0px;
  }
}

/* ORGANISMS */

/* RightSideBar.jsx */
.o-rsb-upcoming-sessions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}

.o-rsb-upcoming-sessions > label {
  color: var(--body-text-on-white);
  margin-bottom: 8px;
}

.o-rsb-session-item {
  margin: 24px 0px;
}

.o-rsb-session-item > h5 {
  letter-spacing: 1px;
}

.o-rsb-session-item > div {
  margin-top: 12px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.o-rsb-session-item > div > p {
  font-size: 12px;
}

.o-rsb-session-item > div > img {
  width: 32px;
  height: 32px;
  border-radius: var(--corner-radius);
  margin-right: 12px;
}

.component-window {
  position: relative;
  width: 544px;
  margin-left: 182px;
  margin-bottom: 24px;
}

.component-tab {
  width: 544px;
  margin-top: 18px;
  background-color: #1a1c1d;
  border-radius: var(--corner-radius);
}

/* Stop the first element from push down */
/* .component-window :first-child {
	margin-top: 0px; */
/* } */

.component-tab:last-of-type {
  margin-bottom: 10%;
}

/* MentorDashboard / Stats.jsx */

.o-mds {
  width: 100%;
  margin: 18px 0px;
}

.o-mds > div {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
}

.o-mds > label {
  color: var(--body-text-on-black);
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 12px 0px;
}

/*  */

.o-mdr {
  width: 100%;
}

.o-mdr > label {
  color: var(--body-text-on-black);
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 18px 0px;
}

@media all and (max-width: 900px) {
  .o-mds > div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

/* MentorDashboard / Sessions.jsx */

.o-mdsession-container {
  margin-top: 12px;
  background-color: var(--module-bg-dark);
  border-radius: var(--corner-radius);
}

.o-mdsession-timeline-container:first-child {
  margin-top: 12px;
}

o-mdsession-timeline-container:last-child {
  border-radius: var(--corner-radius);
}

.o-mdsession-timeline > label {
  color: var(--body-text-on-black);
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0px 0px 12px 24px;
  border-bottom: 2px solid var(--black);
}

.o-mdsession-menu {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 0px 12px 24px;
  border-bottom: 2px solid var(--black);
}

.o-mdsession-menu > h4 {
  margin-right: 24px;
  color: var(--body-text-on-white);
  cursor: pointer;
}

.o-mdsession-menu-selected {
  border-bottom: 2px solid var(--primary-colour);
  color: #fff !important;
}

.o-mdsession-nodata {
  padding: 32px;
  margin: auto;
  width: 100%;
  text-align: center;
  height: 112px;
}

@media all and (max-width: 900px) {
  .o-mdsession-menu {
    padding: 12px 0px 12px 12px;
  }
  .o-mdsession-menu > h4 {
    margin-right: 12px;
  }
  .o-mdsession-timeline > label {
    padding: 0px 0px 12px 12px;
  }
  .o-mdsession-timeline-container:first-child {
    margin-top: 8px;
  }
}

/* MentorDashboard / Mentees.jsx */

.o-md-mentees {
}

.o-md-mentees > label {
  color: var(--body-text-on-black);
  display: inline-block;
  width: 100%;
  height: 100%;
  padding-bottom: 12px;
  border-bottom: 2px solid var(--black);
}

.section-label {
  font-family: "GT-America-Extended-Bold";
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 16px;
  color: #fff;
}

.input-button {
  padding: 6px 16px;
  height: 26px;
  background: #000000;
  border-radius: 28px;
  text-align: center;
  font-weight: bold;
  font-family: "GT-America-Standard";
  font-size: 12px;
  line-height: 14px;
}

.input-icon {
  padding: 7px 6px;
  width: 26.43px;
  height: 26px;
  background: transparent;
  border-radius: 24px;
  margin-left: 8px;
}

.input-action {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right: 20px;
  top: 32px;
}

.input-action-textarea {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right: 20px;
  top: 54px;
}

.input-action-textarea > button {
  background-color: var(--primary-colour);
  font-family: "GT-America-Standard";
  font-size: 12px;
  line-height: 14px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-action > button {
  background-color: var(--primary-colour);
  font-family: "GT-America-Standard";
  font-size: 12px;
  line-height: 14px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}

.bottom-border {
  border: none !important;
  padding: 0px !important;
  border-bottom: 2px solid #000 !important;
  margin-top: 0px !important;
}
@media all and (max-width: 900px) {
  .input-button {
    top: 23px;
    max-width: 80px;
  }
}

.section-header {
  margin-bottom: 16px;
}

.section > .section-content > .section-header,
.header-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.header-action > .action-icons {
  width: 52px;
}

.section > .section-content > .section-header > button {
  border-radius: var(--corner-radius);
}

.profile-video,
.profile-photo,
.new-skill-block,
.flex-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.profile-video > .media-content > .info-container > .content {
  margin-top: 0px;
}

.profile-video {
  width: 55%;
}

.profile-photo {
  width: 42%;
}

.profile-section-media {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
}

.media-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.border-black {
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--black) !important;
  margin-top: 16px;
  margin-bottom: 16px;
}

.border-black-availablility {
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--black) !important;
  margin-top: 16px;
  margin-bottom: 8px;
}

.border-light {
  width: 720px;
  border: none;
  border-bottom: 1px solid var(--field-outline-colour) !important;
  margin-top: 24px;
  margin-left: -24px;
  margin-bottom: 24px;
}

.media-content {
  position: relative;
}

.info-container {
  min-height: 200px;
}

.info-container > .content {
  margin-top: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 124px;
  background: #000;
  border-radius: var(--corner-radius);
  color: #fff;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 0px;
  height: 200px;
}

.info-container > .content > p {
  max-width: 60%;
  text-align: center;
  font-size: 12px;
  padding-bottom: 4px;
}

.ellipse {
  background-color: var(--primary-colour);
  border: 1px solid #66686a;
  padding: 5px 16px;
  border-radius: 28px;
  height: 26px;
}

.ellipse-hollow {
  background-color: var(--black);
  width: 100%;
  padding: 5px 16px;
  border-radius: 28px;
  height: 27px;
  font-size: 12px;
  font-family: "GT-America-Standard";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ellipse:hover,
.ellipse:focus,
.ellipse:active,
.ellipse-hollow:hover,
.ellipse-hollow:focus,
.ellipse-hollow:active {
  background-color: var(--black);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #fff;
}

.finances > .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 1px solid #66686a;
  padding: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.finances > .info > p {
  margin-left: 10px;
}

.info-box {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.info-box > .info-section > .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.info-box > .info-section > .header > * {
  margin-right: 5px;
  font-size: 14px;
  line-height: 140%;
}
.bold-header > * {
  font-family: "GT-America-Extended-Bold";
  color: #fff;
  margin-right: 5px;
  font-size: 14px;
  line-height: 140%;
}

.booking-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
}

.radio-container {
  border: 2px solid #000;
  background-color: #000;
  border-radius: 5px;
  margin-right: 3px;
  padding: 24px;
  width: 100%;
}

.radio-container-selected {
  border: 2px solid var(--primary-colour);
}
.radio-container > .content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: auto;
  width: 100%;
}

.radio-container > .content > p {
  text-align: center;
}

.modal-content > .header,
.highlight-input,
.input-with-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.modal-content > .header {
  /* border-bottom: 1px solid #e9e9ea; */
  padding-bottom: 20px;
  margin-bottom: 0px;
}
.modal-content > .content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.highlight-input > div {
  width: 47%;
  text-align: left;
  margin-top: 10px;
}

.highlight-textarea {
  text-align: left;
  margin-top: 13px;
}

.highlight-input > div > label,
.highlight-textarea > label {
  font-family: "GT-America-Extended-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.highlight-input > div > *,
.highlight-textarea > * {
  margin-top: 7px;
}

.modal-action > button {
  float: right;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 12px;
}

textarea {
  height: auto;
}

.blue {
  color: var(--primary-colour) !important;
}

.blue-border {
  border: 1px solid var(--primary-colour) !important;
}

.account-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  height: 96px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--module-bg-dark);
  width: calc(100vw - 74px);
  margin-left: 74px;
  z-index: 12;
  border-bottom: 2px solid black;
  padding: 0px 20%;
}

.account-header > div {
  margin-left: 21px;
}

.profile-img > img {
  border-radius: 50%;
}

.account-title {
  font-size: 24px;
  color: #fff;
  font-family: "GT-America-Extended-Bold";
}

.account-title-black {
  font-size: 24px;
  color: var(--black);
  font-family: "GT-America-Extended-Bold";
  line-height: 100%;
}

.joining-date {
  font-size: 16px;
  color: var(--body-text-on-black);
  font-family: "GT-America-Standard";
}

.modal-helper-text {
  font-size: 14px;
  margin-top: 6px;
  color: var(--body-text-on-black);
  font-family: "GT-America-Standard";
}

.account-content-container {
  position: relative;
  /* margin-left: 72px; */
  height: 100%;
  width: 900px;
  padding: 112px 60px 0px 60px;
  margin-bottom: 24px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.account-vertical-nav-container {
  position: relative;
  top: 110px;
}

.red-asterik {
  content: "*";
  color: "red";
}

.skills-list {
  display: inline-block;
}

.skill-pill {
  height: 24px;
  border: 1px solid var(--body-text-on-black);
  border-radius: 28px;
  padding: 4px 15px;
  margin: 5px;
  font-family: "GT-America-Standard";
  color: var(--black);
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: left;
}

.input-with-button > div {
  width: 80%;
}

.input-with-button > div > input {
  margin-top: 7px;
  padding: 0px 12px;
}

.input-with-button > div > span {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  font-size: 14px;
  font-family: "GT-America-Standard";
  right: 180px;
  margin-top: 18px;
}

.input-with-button > button {
  margin-top: 7px;
}

.checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  color: var(--field-outline-colour);
}

.checkbox > p {
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  font-family: "GT-America-Standard";
}

.singleline-input-container {
  height: 90px;
}

.border-hollow {
  border: 1px solid var(--body-text-on-white);
}

.input-btn-icon-padding {
  padding-right: 26%;
}

.input-two-icon-padding {
  padding-right: 23%;
}

.input-icon-padding {
  padding-right: 10%;
}

.input-btn-padding {
  padding-right: 18%;
}

.padding_with_border {
  padding: 20px 0px;
  border-bottom: 1px solid #000;
}

.padding_with_border:last-child {
  border: none;
}

.input-error-message {
  color: red;
  margin-bottom: 17px;
}

.margin-top-25 {
  margin-top: 25px;
}

.header-container {
  height: 78px;
  background: #000000;
  padding: 20px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.login-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  color: #bfbfbf;
  width: 520px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.login-action-container {
  color: #bfbfbf;
}

@media (min-width: 1301px) {
  .avatar-mp-cube {
    bottom: 3px;
  }
}

.a-pdd {
  background-color: var(--black);
  color: var(--body-text-on-black);
  padding: 6px 15px;
  height: 32px;
  border-radius: 16px;
  width: -moz-fit-content;
  width: 108px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.open {
  border-radius: 16px 16px 0px 0px;
}

.a-pdd-dropdown {
  position: absolute;
  background-color: var(--black);
  color: var(--body-text-on-black);
  top: 32px;
  width: 108px;
  border-radius: 0px 0px 16px 16px;
  -webkit-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.a-pdd-dropdown > div {
  width: 100%;
  border-top: 1px solid var(--module-bg-colour);
}

.a-pdd-dropdown > div:hover {
  background-color: var(--module-bg-colour);
}
.a-pdd-dropdown > div:last-child:hover {
  border-radius: 0px 0px 16px 16px;
}

.a-pdd-dropdown > div:hover > p {
  color: #fff;
}

.a-pdd-dropdown > div > p {
  text-align: center;
  font-size: 14px;
  padding: 6px 0px;
}

.pricing-description {
  max-width: 600px;
  margin-bottom: 40px;
  color: #6e6e6e;
  font-size: 18px;
  line-height: 140%;
}

.pricing-description > span {
  font-weight: bold;
  color: var(--black);
}

.package-benefit {
  margin-top: 15px;
  color: #6e6e6e;
}

.package-benefit > span {
  color: #000;
  text-decoration: underline;
}

.pricing-container > h3 {
  color: hsla(0, 0%, 100%, 0.5);
  margin-bottom: 30px;
}

.pricing-container > .amount {
  color: #fff;
  font-size: 20px;
  font-family: var(--bold-font);
}

.pricing-container > .amount > span {
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 20px;
  font-family: var(--bold-font);
}

.pricing-container > .benefit,
.pricing-container > .detailing {
  margin-bottom: 30px;
  line-height: 28px;
}
.pricing-container > p {
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 16px;
  font-family: var(--regular-font);
}

.pricing-container > p > span {
  color: #fff;
  font-size: 16px;
  font-family: var(--regular-font);
}

.pricing-container > button,
.pricing-container > button:hover {
  position: absolute;
  font-size: 16px;
  color: #fff;
  font-family: var(--bold-font);
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px;
  height: auto;
  text-align: left;
  background-color: inherit;
  bottom: 32px;
}

.pricing-toggle-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 20px;
  width: 350px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media all and (max-width: 900px) {
  .a-pdd > p {
    line-height: 140%;
  }
}

/* Modify Material UI */
.MuiButton-root {
  box-shadow: none;
}

.MuiButtonBase-root {
  box-shadow: none;
}

.MuiPickersToolbar-toolbar {
  background-color: var(--primary-colour);
}

.MuiPickersDay-daySelected {
  background-color: var(--primary-colour);
}

.MuiPickersDay-daySelected > span > p {
  color: #fff;
}

.MuiInput-root > input {
  background-color: #000;
  border: none;
  color: #fff;
  margin-top: 2px;
  border: 1px var(--body-text-on-white) solid;
  width: 100%;
}

.MuiInput-root > input.focus-visible {
  border: none;
}

.MuiInputBase-input.MuiInput-input:hover {
  border: 1px var(--body-text-on-white) solid;
  box-shadow: none;
}

.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
  margin-top: 10px;
}

.MuiTypography-h4,
.MuiTypography-body1,
.MuiTypography-caption {
  font-family: var(--regular-font) !important;
}

/* Overrides for React Tour */
.react-joyride__beacon {
  box-shadow: none;
}

/* Ovverides for Cicle.ci */
#standard-layout-header {
  height: 74px;
}

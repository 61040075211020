.calendarDay {
	height: 42px;
	width: 100px;
	border-radius: var(--corner-radius);
	border: 1px solid var(--body-text-on-white);
	margin: 4px 6px;
	text-decoration: none;
	background-image: -o-linear-gradient(
		transparent 20px,
		var(--body-text-on-white) 5px,
		var(--body-text-on-white) 5px,
		transparent 21px
	);
	background-image: linear-gradient(
		transparent 20px,
		var(--body-text-on-white) 5px,
		var(--body-text-on-white) 5px,
		transparent 21px
	);
	background-position: 99% 50%;
	cursor: pointer;
    position: relative;
}

.calendarDay > p {
	margin: 9px auto;
	text-align: center;
	color: var(--body-text-on-white);
}

.calendarDay:hover {
	border: 1px solid var(--primary-colour);
}

.active {
	border: 1px solid #000;
	background-image: none;
}

.active > p {
	color: #fff;
}

.selected {
	/* border: 1px solid var(--primary-colour) !important; */
	background-color: var(--primary-colour);
	background-image: none;
	color: #fff;
}

.disable {
	opacity: 0.3;
	background-image: none;
}

.blank {
	border: none;
}

.blank:hover {
	border: none;
}

.requests_today {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    color: #fff;
    padding: 3px 3px;
    border-radius: 0px 0px 3px 0px;
    height: 16px;
    width: 16px;
    font-size: 8px;
    border: solid 1px #000;
}

@media (max-width: 900px) {
	.calendarDay {
		height: 32px;
		background-image: -o-linear-gradient(
			transparent 17px,
			var(--body-text-on-white) 5px,
			var(--body-text-on-white) 5px,
			transparent 18px
		);
		background-image: linear-gradient(
			transparent 17px,
			var(--body-text-on-white) 5px,
			var(--body-text-on-white) 5px,
			transparent 18px
		);
	}
	.active {
		background-image: none;
	}
	.selected {
		background-image: none;
	}
}

.container {
    width: 100%;
    background-color: #1A1C1D;
    border-radius: 3px;
}

.preview_container {
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.title {
    width: 50%;
    cursor: pointer;
}

.title:hover {
    color: var(--primary-colour);
    text-decoration: underline;
}

.progress {
    width: 180px;
    display: flex;
}

.progress>p {
    width: 36px;
    margin-right: 12px;
}

.progress>div {
    margin-top: 7px;
}

.due_date {
    width: 120px;
}

.toggle_key_results_button {
    box-sizing: initial;
    width: 24px;
    height: 24px;
    border: 1px solid #66686A;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 32px;
    align-content: center;
}

.toggle_key_results_button>img {
    width: 24px;
    height: 24px;
    padding: 2px;
}

.divider {
    width: 100%;
    background-color: #000;
    height: 1px;
}

.key_result {
    padding: 24px;
}

@media all and (min-width: 1320px) {
    .container {}
}
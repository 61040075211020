.transcript_container {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 120px);
    position: relative;
    top: 112px;
    padding: 32px;
    padding-top: 4px;
}

.phrase_block {
    padding: 12px;
}

.phrase {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 8px;
}

.phrase > span {
    color: var(--body-text-on-black);
    font-size: 14px;
}

.phrase > span:hover {
    background-color: var(--primary-colour);
    cursor: pointer;
}

.highlight {
    background-color: var(--primary-colour-hover);
}
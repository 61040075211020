.container {
    width: 100%;
    background-color: #1A1C1D;
    display: flex;
    flex-direction: row;
    border-radius: 3px;
    border: 1px solid #1A1C1D;
    position: relative;
    height: 42px;
}

.container>input {
    width: 100%;
    height: 100%;
    height: 42px;
    background-color: #1A1C1D;
    border: none;
    outline: none;
    color: #BFBFBF;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 36px;
    margin: 0px;
}

.container>img {
    height: 20px;
    width: 20px;
    margin-top: 0px;
    position: absolute;
    top: 12px;
    left: 12px;
}

.container>div {
    background-color: #1A1C1D;
    font-family: 'GT-America-Standard';
    width: fit-content;
    padding: 12px 14px;
    border-left: 1px solid #66686A;
    border-radius: 0px;
    cursor: pointer;
}

.container>div>p {
    display: none;
}

.container>div>img {
    filter: brightness(0.7);
    margin-top: 0px;
    height: 18px;
    width: 18px;
}

.container>div>img:hover {
    filter: brightness(1);
}

.container>div>img {}

@media all and (min-width: 900px) {


    .container>div {
        width: 224px;
        justify-content: space-around;
        display: flex;
        border-radius: 0px;
        padding: 12px 18px;
    }

    .container>div>p {
        display: inline;
        margin-top: 0px;
        font-family: 'GT-America-Standard';
        font-size: 14px;
    }

    .container>div>p:hover {
        color: #FFFFFF;
    }

    .container>img {
        margin-right: 6px;
    }
}

@media all and (min-width: 1320px) {
    .container {}
}
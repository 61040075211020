.container {
    margin: auto;
    margin-top: 20vh;
    height: 120px;
    width: 320px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.container > h4 {
    text-align: center;
}

.container > div {
    margin: auto;
}
.container {
  padding: 18px;
}

.title {
  text-align: center;
}

.button {
  margin: auto;
  position: relative;
  margin-top: 24px;
  width: 180px;
}

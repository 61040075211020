.subContainer {
    width: calc(50% - 12px);
    background-color: var(--module-bg-dark);
    border-radius: var(--corner-radius);
    margin-right: 24px;
    padding: 24px 24px;
}

.openGraph {
    width: 100%;
    border-radius: 8px;
    margin-top: 18px;
    width: 50%;
}

.shareContainer {
    margin-top: 18px;
    background-color: #000;
    border-radius: var(--corner-radius);
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 16px;
    margin-bottom: 12px;
    width: 50%;
    height: 100%;
}

.marketing_container {
    display: flex;
    flex-direction: row;
    gap: 18px;
    width: 100%;
}

.shareIcon {
    /* margin-top: 1px; */
    margin-right: 4px;
}

.shareContainer>p {
    margin-bottom: 12px;
    font-size: 16px;
}

.socialButton {
    padding: 8px 12px 8px 10px;
    height: 32px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-bottom: 8px;
}

.socialButton>p {
    font-size: 12px;
    font-family: "GT-America-Standard";
    color: #fff;
}

.performanceWidget {
    width: calc(50% - 12px);
    background-color: var(--module-bg-dark);
    border-radius: var(--corner-radius);
    padding: 24px 24px;
    min-height: 420px;
}

.performanceWidgetHeader {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.performanceWidgetHeader>p {
    margin-top: 8px;
}

.performanceWidget>button {
    float: left;
    position: relative;
}

.performanceItemsList {
    list-style: none;
    padding: 0px;
}

.performanceItemsList :last-child {
    border-bottom: none !important;
}

.affilate_container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

@media all and (max-width: 900px) {

    .marketing_container {
        flex-direction: column;
    }

    .openGraph {
        display: none;
    }

    .subContainer {
        width: 100%;
    }

    .shareContainer {
        justify-content: space-between;
        width: 100%;
    }

    .shareContainer>p {
        margin-top: 7px;
    }

    .shareIcon {
        margin: 0px;
    }
}
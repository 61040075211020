.container {
  padding: 12px 24px;
  position: relative;
  width: 100%;
}

.loading {
  margin: auto;
  width: 100%;
}

.blackout_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  /* pointer-events: none; */
  z-index: 200;
  display: flex;
  align-items: center;
}

.blackout_overlay_text {
  opacity: 1;
  color: #fff;
  background: #1a1c1d;
  position: relative;
  /* bottom: 24px; */
  padding: 24px;
  z-index: 201;
  border-radius: 8px;
  width: 100%;
}

.title {
  font-size: 21px;
  margin-bottom: 3px;
}

.offers {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.offers>div {
  margin-bottom: 8px;
}

.component_container {
  border-bottom: 1px solid black;
  margin-bottom: 12px;
  padding-bottom: 12px;
}

.label {
  text-transform: uppercase;
  margin-top: 8px;
}

.checkout {
  width: 100%;
  margin: 12px 0px;
}

.success_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 18px;
  /* border-bottom: 1px solid black; */
}

.success_container>h3 {
  width: 100%;
  text-align: center;
  margin: auto;
  margin-top: -8px;
}

.success_subtitle {
  width: 100%;
  text-align: center;
  margin: auto;
   font-family: var(--regular-font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
}

.success_information {
  margin-top: 8px;
  margin-bottom: 12px;
  width: 100%;
  font-family: 'GT America';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  color: var(--body-text-on-black);

}

.close_button {
  margin-bottom: 24px;
  display: inline;
  margin-top: 12px;
}

@media (min-width: 768px) {

  .close_button {
    display: none;
  }

  .container {
    padding: 24px 24px;
  }

  .title {
    font-size: 24px;
  }

  .remaining_credits {
    font-size: 14px;
  }

  .offers {
    flex-direction: row;
    justify-content: space-between;
  }

  .offers>div {
    margin-bottom: 0px;
  }

  .offers {
    /* padding-bottom: 12px; */
  }
}

@media (min-width: 1024px) {
  .container {
    width: 544px;
  }

  .success_container {
    width: 544px;
  }
  .blackout_overlay_text {
    position: absolute;
    width: 498px;
  }
}
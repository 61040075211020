.toggle_switch {
	position: relative;
	display: inline-block;
	width: 36px;
	height: 18px;
}
.toggle_switch input[type="checkbox"] {
	display: none;
}
.toggle_switch .switch {
	position: absolute;
	cursor: pointer;
	background-color: #000;
	border-radius: 8px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: background-color 0.2s ease;
}
.toggle_switch .switch::before {
	position: absolute;
	content: "";
	left: 4px;
	top: 3px;
	width: 12px;
	height: 12px;
	background-color: #fff;
	border-radius: 50%;
	transition: transform 0.3s ease;
}
.toggle_switch input[type="checkbox"]:checked + .switch::before {
	transform: translateX(16px);
	background-color: #fff;
}
.toggle_switch input[type="checkbox"]:checked + .switch {
	background-color: #5440f0;
}

.container {
    width: 100%;
    border-bottom: 2px solid rgba(1, 1, 1, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    /* padding: 8px; */
    padding-bottom: 4px;
    cursor: pointer;
    width: fit-content;
}

.container > span {
    color: #fff;
    font-size: 14px;
}

.container > svg {
    width: 24px;
    height: 24px;
}

.container:hover {
    border-bottom: 2px solid rgb(255, 255, 255, 1);
}

.selected {
      border-bottom: 2px solid var(--primary-colour);
}

@media all and (min-width: 900px) {
    .container > svg {
        width: 32px;
        height: 32px;
    }
    .container > span {
        font-size: 16px;
    }
    .container {
        gap: 8px;
        padding-bottom: 8px;
    }
}

@media all and (min-width: 1320px) {
    .container {

    }
}
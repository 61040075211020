.container {
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: #1c1c1c;
    color: #ffffff;
    font-family: Arial, sans-serif;
    margin: 16px 0;
    width: 100%;
}

.container>h4 {
    /* text-transform: uppercase; */
}

.slot_list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    gap: 18px;
    padding: 18px 0px;
}

.selected_slot_container {
    display: flex;
    gap: 18px;
    padding: 18px 0px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.slot {
    width: 180px;
    padding: 12px 16px;
    border: 1px solid #66686a;
    border-radius: 5px;
    cursor: pointer;
    background-color: #000;
    transition: display 0.3s;
    color: #dadada;
    font-family: "GT America";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.8s linear, visibility 0.8s linear;
}

.hide_slot {
    visibility: hidden;
    opacity: 0;
    /* height: 0; */
    overflow: hidden;
    transition: opacity 0.8s linear, visibility 0.8s linear;
}

:global {
    .glow {
        & :local {
            animation: glow 0.8s ease-in-out once;
        }
    }
}

.glow {
    animation: glow 1s ease-in-out forwards;
    border: 1px solid transparent;
    /* To maintain layout integrity */
    border-radius: 5px;
    /* Optional: adjust based on your design */
}

@keyframes glow {

    0%,
    100% {
        box-shadow: 0 0 8px 4px rgba(255, 255, 255, 0);
        /* Initial and end state */
    }

    50% {
        box-shadow: 0 0 16px 8px rgba(88, 61, 187, 0.6);
        /* Midpoint, maximum glow */
    }
}


.slot:hover {
    background-color: #3c3c3c;
}

.note {
    margin-top: 20px;
    font-size: 14px;
    text-align: start;
}

.note>span {
    font-style: bold;
}

.selected {
    border: 1px solid #4333c0;
    background-color: #000;
    color: #fff;
    display: flex;
    height: 42px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 3px;
    font-size: 12px;
}

.blue_button {
    display: flex;
    height: 42px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 3px;
    /* border: 1px solid #fff; */
    background: #5440f0;
}

.red_button {
    display: flex;
    height: 42px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 3px;
    background: #ff8571;
}

.red_button:hover {
    background: #ff6b5c;
}

.black_button {
    display: flex;
    height: 42px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 3px;
    /* border: 1px solid #fff; */
    background: #000;
}

.black_button:hover {
    background: #333;
}

.selected,
.red_button,
.blue_button,
.black_button {
    width: 46%;
}

@media (min-width: 900px) {

    .selected,
    .red_button,
    .blue_button,
    .black_button {
        width: 190px;
    }

    .selected {
        font-size: 14px;
    }

}
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
}

.objective_info {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form_container {
    width: 100%;
}

@media all and (min-width: 900px) {
    .container {
        width: 564px;
    }
    .objective_info {
        padding: 0px;
    }
    .form_container {
        width: 564px;
        align-self: center;
    }
}

@media all and (min-width: 1320px) {
    .container {}
}
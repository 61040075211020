.container {
	background-color: var(--module-bg-dark);
	width: 172px;
	height: 172px;
	border-radius: var(--corner-radius);
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
}

.placeholder {
	cursor: pointer;
	margin: auto;
	margin-bottom: 16px;
	width: 98px;
	height: 98px;
	border-radius: 50%;
	background-color: var(--module-bg-colour);
	display: flex;
	justify-content: center;
}

.placeholder > img {
	width: 24px;
	height: 24px;
	opacity: 60%;
	position: relative;
	top: 38px;
}

.uploadBtn {
	height: 28px;
	width: 122px;
	background-color: transparent;
	border: 1px solid var(--body-text-on-white);
	border-radius: 28px;
	padding: 6px;
	font-family: var(--regular-font);
}

.uploadBtn:hover {
	background-color: #fff;
	color: #000;
	box-shadow: none;
}

.image {
	height: 172px;
	border-radius: var(--corner-radius);
	position: relative;
	display: block;
	margin: auto;
}

.container {
	width: 100%;
	position: relative;
}

.input {
    background: #000;
    width: 100%;
    border: none;
    border-radius: 3px;
    padding: 8px 35px 8px 12px;
    color: #fff;
    font-size: 14px;
    margin: 8px 0px;
	height: 42px;
}

.button_plus {
	position: absolute;
    top: 20px;
    right: 12px;
	width: 18px;
	height: 18px;
	background: var(--primary-colour);
	cursor: pointer;
	border: 1px solid var(--primary-colour);
	border-radius: 50%;
}

.button_plus:after {
	content: "";
	position: absolute;
	transform: translate(-50%, -50%);
	height: 2px;
	width: 50%;
	background: #fff;
	top: 50%;
	left: 50%;
}

.button_plus:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	height: 50%;
	width: 2px;
}

.button_plus:hover:before,
.button_plus:hover:after {
	background: #fff;
	transition: 0.2s;
}

.button_plus:hover {
	background: var(--primary-colour-hover);
	transition: 0.2s;
}

.page {
    width: 100%;
    position: relative;
}

.header_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-right: 108px;
    height: 48px;
}

.header_container>button {
    display: none;
}

.container {
    position: relative;
    top: 72px;
    padding: 12px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.header_row {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    height: 48px;
    background: #000000;
    border: 1px solid #25282A;
    border-radius: 4px;
    padding: 16px 24px;
}

.header_row>label {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #BFBFBF;
}

.objectives_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.goal_label {
    width: 50%;
}

.progress_label {
    width: 180px;
}

.due_label {
    width: 120px;
}

.placeholder_label {
    width: 26px;
}

@media all and (min-width: 900px) {
    .container {
        /* top: 96px; */
        padding: 24px 48px;
    }

    .header_row {
        display: flex;
    }

    .header_container>button {
        display: block;
    }

}
.contentContainer {
  position: relative;
  /* margin-left: 72px; */
  width: 100%;
  display: block;
  height:100%;
  margin: 0px;
  padding: 0px 6px 0px 6px;
  margin-top: 96px;
}

@media all and (min-width: 900px) {
  .contentContainer {
    margin-top: 96px;
    padding: 25px 60px;
  }
}

.container {
  display: inline-block;
  padding: 6px 16px;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  background-color: var(--module-bg-colour);
  border-radius: 9999px;
}

.small {
  padding: 2px 8px;
  font-size: 8px;
  line-height: 10px;
  height: 14px;
}

.primary {
  font-family: "GT-America-Extended-Bold";
  background-color: var(--primary-colour);
}

.square {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid var(--body-text-on-white);
  border-radius: var(--corner-radius);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: var(--bold-font);
  font-size: 18px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--black);
}

.selected {
  background-color: var(--primary-colour) !important;
}

.iconChip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* border: 1px solid var(--body-text-on-white); */
  border-radius: var(--corner-radius);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: var(--bold-font);
  font-size: 18px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--module-bg-colour);
}

.chipText {
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-family: var(--bold-font);
  color: #fff;
  margin-top: 8px;
}

.chip {
  background-color: var(--primary-colour-presses);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 32px;
  padding: 3px 8px;
  font-size: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.iconContent {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.container {
    width: 100%;
    height: 8px;
    background-color: #25282A;
    border-radius: 3px;
  }
  
  .fill {
    height: 100%;
    background-color: #5440F0;
    border-radius: 3px;
    transition: width 0.7s ease-in-out;
  }
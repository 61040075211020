.container {
	width: 100%;
	height: fit-content;
	background: #ffffff;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
	border-radius: var(--corner-radius);
	position: relative;
}

.toggle {
	height: 104px;
	padding: 28px;
	cursor: pointer;
}

.title {
    width: 80%;
    height: 44px;
	position: relative;
}

.title > h3 {
	color: #000;
	vertical-align: middle;
	line-height: 110%;
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 14px;
}

.arrow {
	height: 24px;
	width: 24px;
	transform: rotate(0deg);
	transition: transform 0.3s linear;
    position: absolute;
    top: 38px;
    right: 42px;
}

.arrow_open {
	transform: rotate(90deg);
	transition: transform 0.3s linear;
}

.hidden_content {
	padding: 28px;
	padding-top: 0px;
	margin-top: -12px;
	color: #000;
	text-align: left;
	font-family: "GT-America-Standard";
}


@media (min-width: 900px) {
	.title > h3 {
		font-size: 18px;
	}
	.arrow {
		height: 30px;
		width: 30px;
	}
}
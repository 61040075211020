/* TODO: Move Globals */

.container {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 8px;
  margin-bottom: 24px;
  width: 256px;
  height: 360px;
  margin: 12px;
  overflow: hidden;
}

.btnClearSm {
  position: absolute;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  border-radius: 0px;
  padding: 12px 0px;
  color: #fff;
  width: fit-content;
}

.btnClearSm:hover {
  background-color: transparent;
  color: #fff;
  opacity: 0.9;
}

/* End GLOBALS */

.btnContainer {
  position: absolute;
  top: 290px;
  width: 100%;
}

.card_header {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 8px;
  margin-bottom: 24px;
  width: 256px;
  height: 360px;
  margin: 12px;
  overflow: hidden;
}

.mentorCardContainer {
  border-radius: 5px;
  margin-bottom: 20px;
  width: 256px;
  height: 360px;
  position: relative;
  padding: 24px;
  background: linear-gradient(to bottom, transparent 0%, black 95%);
  height: 610px;
  top: 0px;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.mentorCardContainer:hover {
  top: -250px;
  background: rgba(0, 0, 0, 0.7);
}

.mentorCardContainer:hover .infoText {
  white-space: normal;
  overflow: normal;
  text-overflow: normal;
}

.infoContainer {
  margin-top: 6px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: row;
}

.infoIcon {
  width: 16px;
  position: relative;
  left: 0px;
  top: 2px;
  margin-right: 6px;
}

.infoText {
  font-size: 12px;
  position: relative;
  width: 100%;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.bioText {
  font-size: 12px;
  position: relative;
  width: 100%;
  line-height: 16px;
  white-space: pre-line;
}

.mentorContentContainer {
  position: relative;
  top: 248px;
  max-height: 80px;
}

.mentorContentContainer > h3 {
  width: 220px;
}

.mentorContentContainer > p {
  position: relative;
  width: 210px;
  overflow: hidden;
  height: 2.6em; /* exactly three lines */
}

.skillsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 24px;
}

.bio {
  margin-top: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-right: 48px;
}

.isAvaDay {
  color: var(--body-text-on-black);
  padding: 10px 2px;
}

.notAvaDay {
  color: var(--body-text-on-white);
  padding: 10px 3px;
}

/* Mobile */
@media (max-width: 900px) {
  .infoContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .skillsContainer {
    overflow: scroll;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
}

@media all and (max-width: 480px) {
  .infoContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .mentorCardContainer {
    padding: 18px;
  }
  .mentorContentContainer {
    top: 258px;
  }
  .btnContainer {
    top: 284px;
    display: flex;
    justify-content: space-between;
  }
}

/* SESSION CALANDER */
.sessionCalendarMonth {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
  padding: 24px;
  background-color: var(--module-bg-dark);
  border-radius: var(--corner-radius);
  margin-top: 18px;
  min-height: 364px;
}

.sessionCalendarMonthHeader {
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 12px;
}

.sessionCalendarMonthNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%;
  margin-top: 12px;
}

.sessionCalendarMonthNav > img {
  height: 30px;
  width: 30px;
}

.sessionCalendarMonthHeader > h4 {
  width: 100px;
  margin: 4px 6px;
}

.sessionCalendarWeekRow {
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  /* justify-content: space-evenly; */
}

.bookSessionHeader {
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  background-color: var(--module-bg-dark);
  width: 544px;
  height: 206px;
  padding: 24px;
}

.profileAvatarSmall {
  width: 130px;
  height: 130px;
  border-radius: var(--corner-radius);
}

.profileAvatarImgSmall {
  width: 130px;
  height: 130px;
  border-radius: var(--corner-radius);
}

.bookSessionDetailsContainer {
  border-top: 1px solid var(--body-text-on-black);
  padding-top: 18px;
  min-height: 360px;
}

.dayTitle {
  width: 100%;
  text-align: center;
}

.timezoneContainer {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}

.timezoneContainer > p {
  font-size: 12px;
}
.timezoneContainer > img {
  width: 14px;
  height: 14px;
  margin-top: 1px;
  margin-right: 5px;
  margin-left: 2px;
}

.changeLink {
  font-size: 12px;
  color: var(--primary-colour);
}

/* SESSION CALANDER - Mobile Styles */

@media all and (max-width: 900px) {
  .session-calendar-month-header > h4 {
    font-size: 18px;
    text-align: center;
  }

  .session-selector-container > h4 {
    margin-top: 6px;
    font-size: 18px;
  }
  .session-selector-slot {
    width: 100%;
    margin-right: 0px;
  }
}
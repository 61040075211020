/* Modal.module.css */
.modalOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 200;
}

.modal {
    width: 360px;
    padding: 24px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.modal > h3 {
    font-size: 22px;
    color: #000;
    margin: auto;
    margin-bottom: 24px;
}

.modalOptions {
    display: flex;
    justify-content: space-between;
}

.modalOption {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 152px;
    padding: 12px;
    border: 1px solid transparent;
}

.modalOption:hover {
    border: 2px solid var(--primary-colour-hover);
    border-radius: 8px;
}

.modalOptionImg {
    margin-right: 16px;
    width: 90px;
    margin: auto;
}

.modalOptionText {
    color: #000;
    margin-top: 12px;
}

.closeButton {
    
}
.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    background: #1a1c1d;
    border-radius: 8px;
    margin: 16px 0px;
}

.card {
    width: 100%;
    padding: 16px;
    background: #1a1c1d;
    flex-direction: column;
    border-radius: 8px;
    gap: 16px;
    justify-content: space-between;
}

.mentor_info_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
}

.mentor_info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.mentor_info h1 {
    margin: 0;
    padding: 0;
    margin-bottom: 2px;
}

.headline {
    color: var(--Grey-Text-on-Black, #bfbfbf);
    font-family: "GT America";
    font-size: 14px;
    margin: 0;
    padding: 0;
    line-height: 1.4;
}

.avatar {
    width: 64px;
    height: 64px;
    border-radius: var(--4px, 4px);
}

.session_details {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 4px;
}

.session_details > div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.session_details_text {
    color: var(--Grey-Text-on-Black, #bfbfbf);
    font-family: "GT America";
    font-size: 12px;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.action_buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 24px;
}

.proposed_times {
    margin-bottom: 18px;
}


.details_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--8px, 8px);
    border-top: 1px solid #000;
    padding: 16px;
    width: 100%;
    justify-content: space-between;
}

.agenda {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.agenda>p {
    color: #fff;
    font-family: "GT America";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.button_container {
    gap: 8px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    width: 100%;
    margin: 16px 0px;
    max-width: 100%;
}

.blue_button {
    display: flex;
    height: 42px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #fff;
    background: #5440f0;
    color: #fff;
    cursor: pointer;
}

.red_button {
    display: flex;
    height: 42px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 3px;
    background: #ff8571;
}

.black_button {
    display: flex;
    height: 42px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #fff;
    background: #000;
    color: #fff;
    cursor: pointer;
}

.requested_session_action_container {
    display: flex;
    gap: 8px;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
}

.requested_session_button_container {
    display: flex;
    gap: 16px;
    align-items: start;
    justify-content: start;
    width: 100%;
    flex-direction: row;
}

.sessionCardContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    background: #1a1c1d;
    border-radius: 8px;
    margin: 16px 0px;
}

.sessionCard {
    width: 100%;
    padding: 16px;
    background: #1a1c1d;
    border-radius: 8px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.cardInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
}

.detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.basicDetail h3 {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
}

.objective {
    color: var(--Grey-Text-on-Black, #bfbfbf);
    font-family: "GT America";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    margin: 0;
    padding: 0;
}

.detailBtn {
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 32px;
    border: 1px solid #000;
    background: var(--Black, #000);
    display: none;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
}

.detailBtn:hover {
    background: #1a1c1d;
    border: 1px solid #fff;
}

.infoSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.dateInfo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dateInfo p {
    color: var(--Grey-Text-on-Black, #bfbfbf);
    font-family: "GT America";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.canceledTag,
.completedTag {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.canceledTag p,
.completedTag p {
    color: var(--Grey-Text-on-Black, #bfbfbf);
    font-family: "GT America";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.mobileDetailBtn {
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 32px;
    border: 1px solid #000;
    background: var(--Black, #000);
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
}

.mobileDetailBtn:hover {
    background: #1a1c1d;
    border: 1px solid #fff;
}

.lineSeparator {
    border: none;
    border-top: 1px solid #000;
    width: 100%;
    margin: 0;
}

.sessionDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 16px;
    width: 100%;
}

.fieldTitle {
    color: #fff;
    font-family: "GT America";
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
}

.fieldDetail,
.okrLink {
    color: #fff;
    font-family: "GT America";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.action {
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 16px;
}

.action button {
    display: flex;
    height: 42px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #fff;
    background: #5440f0;
    color: #fff;
    cursor: pointer;
}

.filterContainer {
    display: flex;
    gap: 8px;
    margin: 16px 0;
    padding: 0 16px;
    flex-wrap: wrap;
}

.filterButton {
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid #5440f0;
    background: transparent;
    color: #5440f0;
    font-family: "GT America";
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.filterButton:hover {
    background: rgba(84, 64, 240, 0.1);
}

.filterButtonActive {
    background: #5440f0;
    color: white;
}

@media (min-width: 900px) {

    .details_button {
        display: block;
    }

    .mentor_info>h1 {
        font-size: 32px;
        padding: 0px;
        padding-bottom: 8px;
    }

    .avatar {
        width: 120px;
        height: 120px;
    }

    .card {
        display: flex;
        flex-direction: row;
    }

    .red_button,
    .blue_button,
    .black_button {
        width: 190px;
    }

    .sessionCard {
        flex-direction: row;
    }

    .detailBtn {
        display: flex;
        top: 50%;
        transform: translateY(-50%);
    }

    .mobileDetailBtn {
        display: none;
    }

    .basicDetail h3 {
        font-size: 32px;
        margin-bottom: 8px;
    }

    .action button {
        width: 190px;
    }

    .filterContainer {
        padding: 0;
    }
}
.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  overflow: auto;
  white-space: nowrap;
  padding: 8px;
}

@media all and (min-width: 900px) {
  .container {
    padding: 24px;
    gap: 48px;
    padding-right: 124px;
  }
}

@media all and (min-width: 1320px) {
  .container {
  }
}

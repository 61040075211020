.container {
	width: 72px;
	height: 32px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.confirmation {
	width: 72px;
	height: 32px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
    opacity: 1;
}

.divider {
	height: 32px;
	width: 2px;
	background-color: #66686a;
}

.button {
	width: 32px;
	height: 32px;
	cursor: pointer;
	padding: 7px;
	filter: opacity(0.5);
}

.button:hover {
	filter: opacity(1);
}

.button > img {
	width: 18px;
	height: 18px;
}

.checkmark {
	transform: rotate(45deg);
	height: 20px;
	width: 11px;
	border-bottom: 3px solid #fff;
	border-right: 3px solid #fff;
	margin-top: -5px;
	margin-left: 3px;
}

.container {
}

.order_details {
  margin: 0px;
  width: 100%;
}

.container > h3 {
  font-family: var(--bold-font);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
}

.line_item {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 8px 0px;
}

.line_item > p {
  font-family: var(--regular-font);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #bfbfbf;
  width: 32%;
}

.title {
  text-align: left;
}

.description {
  text-align: left;
}

.price {
  text-align: right;
}

.summary {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 8px 0px;
}

.total {
  width: 70%;
  text-align: right;
  color: #fff;
  text-transform: uppercase;
  font-family: var(--bold-font);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.total_cost {
  width: 30%;
  text-align: right;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

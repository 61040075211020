.textArea {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  resize: none;
  height: 120px;
  padding: 12px 16px;
}

.darkLabel {
  color: #fff;
}
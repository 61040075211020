.container {
  position: relative;
  width: auto;
  height: auto;
  display: inline-block;
}

.pin {
  z-index: 10;
  position: absolute;
  border-radius: 9999px;
  border: 1px solid var(--black);
  background-color: var(--primary-colour);
  transition: 0.3s opacity;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden > .pin {
  opacity: 0;
}

@media all and (max-width: 900px) {
  .container {
  }
}

@media all and (max-width: 1320px) {
  .container {
  }
}

.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.loading_container {
    margin-top: 24%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    /* height: 100vh; */
}

.community_iframe {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    border: none;
}

.community_iframe_hidden {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    border: none;
    display: none;
}


.container {
    width: 100%;
    /* height: 248px; */
    width: 320px;
    background-color: #1A1C1D;
    position: relative;
    border-radius: 8px;
    transition: all .2s ease-in-out;
}

/* .container:hover {
    transform: scale(1.03);
} */

.ready:hover {
    cursor: pointer;
    transform: scale(1.03);
}

.video_thumbnail {
    border-radius: 8px;
    height: 180px;
    width: 320px;
}

.video_thumbnail > p {
    text-align: center;
    width: 100%;
    position: relative;
    top: 50%;
}

.duration_container {
    width: 56px;
    padding: 3px;
    background-color: #1A1C1D;
    height: 24px;
    border-radius: 12px;
    position: absolute;
    right: 8px;
    top: 148px;
}
.duration_container > p {
    text-align: center;
    font-size: 14px;
}

.metadata {
    padding: 8px 12px;
}

.metadata > h3 {
    /* font-family: var(--regular-font); */
}


.metadata > h3 {
    font-size: 18px;
    font-size: 14px;
}

@media (min-width: 720px) {
    /* .container {
        width: 512px;
    }

    .video_thumbnail {
        width: 512px;
        height:278px;
    }
    
    .duration_container {
        right: 8px;
        top: 246px;
    }
    .duration_container > p {
       
    }
    
    .metadata {
      padding: 12px 18px;
    }
    
    
    .metadata > h3 {
        font-size: 21px;
    } */
    
}
.round {
    position: relative;
}

.round label {
    background-color: none;
    border: 1px solid #66686A;
    border-radius: 50%;
    cursor: pointer;
    height: 14px;
    left: 0;
    position: absolute;
    top: 0;
    width: 14px;
}

.round label:after {
    border: 2px solid #1A1C1D;
    border-top: none;
    border-right: none;
    content: "";
    height: 4px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 8px;
}

.round input[type="checkbox"] {
    visibility: hidden;
    box-sizing: border-box;
    margin: 0px;
}

.round input[type="checkbox"]:checked+label {
    background-color: #fff;
    border: none;
}

.round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}
.container {
	margin: 0px 8px;
	/* width: 100%; */
	background-color: #fff;
	padding: 16px;
	border-radius: 8px;
}

.container > p {
	margin: 8px 0px -8px 0px;
	text-align: center;
	color: #424242;
}

.duration_selection {
	display: flex;
	flex-direction: row;
	margin-bottom: 8px;
}

.duration_selection > p {
	margin-left: 0px;
	margin-right: 8px;
	font-size: 12px;
	margin-top: -1px;
}

.duration_selection > p:last-child {
	margin-left: 8px;
	margin-right: 0px;
}

.header_container {
	margin-bottom: 8px;
}

.header_container > h3 {
	color: #000;
}

.plan {
	border-radius: 8px;
	border: 1px #000 solid;
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;
	cursor: pointer;
}

.headline {
	background-color: #000;
	height: 34px;
	border-radius: 8px 8px 0px 0px;
	overflow: hidden;
	border: 1px #000 solid;
	margin: -1px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	padding: 6px 16px;
}

.hline {
	font-family: "GT-America-Extended-Bold";
	color: #fff;
	font-size: 14px;
}

.bline {
	color: #fff;
	font-size: 14px;
}

.heading {
	margin-top: 8px;
    padding: 8px;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.heading > div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.heading > div > h4 {
	color: #000;
	font-size: 16px;
}

.heading > p {
	color: var(--primary-colour);
}

.checkbox {
	margin-top: 5px;
	margin-right: 4px;
}

.benifits {
	padding: 0px 16px;
	list-style: none;
	margin: 0px;
	margin-bottom: 16px;
}

.benifit {
	display: flex;
	flex-direction: row;
	margin-bottom: 4px;
}

.benifit > img {
	width: 16px;
	height: 16px;
	margin: 3px 4px 0px 0px;
}

.benifit_pt1 {
	font-size: 14px;
	color: #000;
}

.benifit_pt2 {
	font-size: 14px;
	color: #000;
	opacity: 50%;
}

.select_btn {
	width: 100%;
	border-radius: 8px;
}

.reffering_mentor {
	display: flex;
	flex-direction: row;
}

.reffering_mentor > p {
	font-size: 12px;
	margin-top: 1px;
	margin-left: 8px;
}

@media (min-width: 900px) {
	.container {
		max-width: 394px;
		border-radius: 8px;
		margin: 24px;
		width: 100%;
		background-color: #fff;
		padding: 24px;
	}
}

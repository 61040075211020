.header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	border-radius: var(--corner-radius);
	padding: 24px;
	background-color: var(--module-bg-dark);
	margin-bottom: 12px;
	padding: 16px 24px 12px 24px;
	border-radius: var(--corner-radius);
}

.header > div:first-child {
	margin-left: 0px;
}
.header > div {
	padding-bottom: 5px;
	margin-left: 15px;
	cursor: pointer;
}
.header > .selected {
	border-bottom: 1px solid var(--primary-colour);
}

.header > .selected > h4 {
	color: #fff;
}

.container {
    display: none;
}

.title_container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 32px;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media all and (min-width: 900px) {
  .container {
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    width: 260px;
    background-color: #1a1c1d;
    flex-direction: column;
    padding: 24px;
    height: 100vh;
  }
}

@media all and (min-width: 1200px) {
  .container {

  }
}

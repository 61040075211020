.container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: fixed;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #000;
	width: 100vw;
	z-index: 12;
	border-bottom: 2px solid var(--module-bg-dark);
	height: 74px;
}

.openMobileNav {
	display: inline;
	right: 24px;
	position: fixed;
}

.creditAvatar {
	position: absolute;
	top: 10px;
	right: 24px;
}

.content {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	width: 100%;
	padding: 12px;
}

/* .container>div {
	margin-left: 18px;
	margin-right: 52px;
} */

@media (min-width: 900px) {
	.container {
		margin-bottom: 0px;
		margin-left: 0px;
		left: 0px;
		width: 100%;
		padding: 0px 122px
	}

	.stickToTop {
		top: 0px;
	}

	.openMobileNav {
		display: none;
	}
}
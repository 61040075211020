/* Earnings Widget */
.container {
    width: 100%;
    background-color: var(--module-bg-dark);
    border-radius: var(--corner-radius);
    padding: 24px 24px;
    min-height: 420px;
}

.header {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.earnings>* {
    margin: auto;
    text-align: center;
}

.earnings {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 96px 0px 12px;
}

.earnings_title {
    color: var(--body-text-on-black);
    padding-bottom: 12px;
}

.title>span {
    font-size: 42px;
    color: #fff;
    font-family: var(--bold-font);
    padding: 4px;
}

.stats>span {
    padding: 4px 8px;
    background-color: var(--primary-colour);
    border-radius: 16px;
    color: #fff;
}

.stripe {
    background-color: var(--black);
    color: var(--body-text-on-black);
    padding: 13px 18px;
    height: 42px;
    border-radius: 42px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 92px;
    position: relative;
    margin: auto;
    cursor: pointer;
}

.stripe:hover {
    background-color: var(--black);
    color: var(--body-text-on-black);
    border: 1px solid #fff;
}

@media all and (max-width: 900px) {
    .container {
        height: 292px;
        padding: 16px;
        margin-top: 16px;
    }

    .stripe>p {
        font-size: 16px;
        line-height: 140%;
    }

    .earnings {
        padding: 52px 0px 12px;
    }

    .stripe {
        top: 54px;
    }
}
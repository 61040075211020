.container {
	height: fit-content;
	width: 100%;
	background-color: #e5e5e5;
	position: relative;
	padding: 48px 0px;
}

.container > p {
	margin: auto;
	text-align: center;
    color: #000;
	margin-top: 24px
}

.logos {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 324px;
}

.logos > img {
	width: 190px;
	margin: auto;
}

@media (min-width: 900px) {
	.container {
		height: 200px;
		padding: 24px 0px 32px 0px;
	}

	.logos {
		max-width: 1154px;
		margin: auto;
		height: 90px;
		justify-content: space-between;
		display: flex;
		position: relative;
		flex-direction: row;
		margin-bottom: 12px;
	}

	.logos > img {
		max-height: 70px;
		width: 180px;
	}

	.container > p {
		margin-top: 0px;
	}
}

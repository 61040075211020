.button {
    height: 40px;
    border-radius: var(--corner-radius);
    cursor: pointer;
    color: #fff;
    font-family: "GT-America-Extended-Bold";
    border: none;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 32px;
    text-align: center;
}

.button:hover {
    background-color: var(--primary-colour);
    color: #fff;
}

.primary {
    composes: button;
    background: var(--primary-colour);
    box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.25);
}

.disabled {
    composes: button;
    background: #8D8F90;
}

.disabled:hover {
    background: #8D8F90;
}

.dark {
    composes: button;
    background-color: var(--black);
    border: 1px solid #fff;
    -webkit-box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.25);
    color: #fff;
}

.dark:hover {
	background: #66686a;
    color: #fff;
}

@media all and (min-width: 900px) {
    .button {
        height: 42px;
    }
}
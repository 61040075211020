.account_content_container {
  position: relative;
  height: 100vh;
  width: 100%;
  padding-top: 96px;
  margin-bottom: 24px;
  margin: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  gap: 16px;
}

.booking_container {
  height: 100%;
  overflow-y: scroll;
  padding-top: 16px;
  padding-bottom: 32px;
}

.booking_container > div:last-child() {
  margin-bottom: 24px;
}

/* Mentor Content */
.mentorContent {
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-color: var(--module-bg-dark);
  border-radius: var(--corner-radius);
  width: 100%;
  height: 206px;
  padding: 24px;
}

.mentorAvatar {
  width: 160px;
  height: 160px;
  border-radius: var(--corner-radius);
  margin-right: 24px;
}

.timezoneContainer {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 8px;
}

.timezoneText {
  font-size: 12px;
  line-height: 16px;
}

.timezoneGlobe {
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.mentorBio {
  margin-top: 12px;
  display: block;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 12px;
  max-width: 304px;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  height: 96px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--module-bg-dark);
  width: calc(100vw - 74px);
  margin-left: 74px;
  z-index: 12;
  border-bottom: 2px solid black;
}

.header > h3 {
  position: relative;
  left: calc(40% - 300px);
}

.successPage {
  width: 360px;
  height: 460px;
  margin: auto;
  margin-top: 12%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.successPage > p {
  text-align: center;
}

.successPage > h2 {
  text-align: center;
}

.successPage > button {
  width: 180px;
  margin: 0px auto;
}

.successAvatar {
  height: 160px;
  width: 160px;
  position: relative;
  margin: 0px auto;
}

.successAvatarIcon {
  position: absolute;
  top: -20px;
  right: -20px;
  padding: 8px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #fff;
  z-index: 20;
}

.successAvatarIcon > img {
  color: #fff;
}

.successAvatarImage {
  width: 100%;
  height: 100%;
  border-radius: var(--corner-radius);
}

@media (max-width: 900px) {
  .header {
    width: 100%;
    margin-left: 0px;
    border-bottom: none;
    padding: 0px;
    /* top: 72px; */
    height: 72px;
  }
  .header > h3 {
    position: relative;
    left: 24px;
  }

  /* Mentor Content */
  .mentorContent {
    width: 100%;
    height: 366px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .mentorAvatar {
    margin-bottom: 12px;
  }

  .booking_container {
    width: 100%;
    left: 0px;
    padding-bottom: 84px;
  }

  .successPage {
    width: 100%;
  }
}

.infoContainer {
  min-height: 200px;
}

.content {
  margin-top: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 124px;
  background: #000;
  border-radius: var(--corner-radius);
  color: #fff;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 0px;
  height: 200px;
}

.content > p {
  max-width: 60%;
  text-align: center;
  font-size: 12px;
  padding-bottom: 4px;
}

.ellipseHollow {
  background-color: var(--black);
  width: 100%;
  padding: 5px 16px;
  border-radius: 28px;
  height: 27px;
  font-size: 12px;
  font-family: "GT-America-Standard";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.borderHollow {
  border: 1px solid var(--body-text-on-white);
}

.ellipseHollow:hover,
.ellipseHollow:focus,
.ellipseHollow:active {
  background-color: var(--black);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #fff;
}

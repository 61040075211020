/* Performance Widget */
.performanceWidget {
  width: 100%;
  background-color: var(--module-bg-dark);
  border-radius: var(--corner-radius);
  margin-right: 24px;
  padding: 24px 24px;
  min-height: 420px;
}

.performanceWidgetHeader {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.performanceItemsList {
  list-style: none;
  padding: 0px;
}

.performanceItemsList :last-child {
  border-bottom: none !important;
}

.stat {
  width: 80px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.stat > p {
  margin-right: 12px;
  color: var(--field-outline-colour);
}

.loading {
  padding-top: 94px;
}

@media all and (max-width: 900px) {
  .performanceWidget {
    height: 308px;
    padding: 16px;
  }
  .lineItem {
    padding: 12px 0px;
  }

  .lineItem > div > p {
    margin: 0px;
    margin-right: 8px;
  }
  .lineItem > p {
    margin: 0px;
  }
}

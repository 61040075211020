.container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.on_black {}

.on_black > label {}

.on_grey {}

.on_white {}

.container>label {
	font-size: 12px;
	/* text-transform: uppercase; */
	display: flex;
}

.container>input {
	width: 100%;
	height: 42px;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 0 10px;
	margin: 5px 0px 0px 0px;
}

.error_label {
	color: var(--error-red);
}

.error_input {
	border: 1px solid var(--error-red) !important;
}

.error {
	margin-top: 3px;
	font-size: 12px;
	color: var(--error-red);
}

.tooltip {
	margin-left: 8px;
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
	text-align: center;
    height: 16px;
    width: 16px;
    background-color: #000;
    border-radius: 50%;
    font-size: 10px;
    border: 1px solid #66686a;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 2px;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

@media (min-width: 900px) {
	.container>label {
		font-size: 14px;
		/* text-transform: uppercase; */
	}

	.container>input {
		margin: 8px 0px 0px 0px;
	}

	.error {
		margin-top: 4px;
		font-size: 14px;
		margin-left: 5px;
	}
}
.container {
    width: 100%;
    padding: 64px 24px;
    display: flex;
    flex-direction: column;
}

.container > * {
    margin: auto;
}

.container > h3 {
    text-align: center;
}

.headshot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-between;
    height: 112px;
    margin: 32px 0px;
}

.headshot > * {
    margin: auto;
}

.headshot > img {
    width: 48px;
    height: 48px;
}

.container > button {
    font-size: 16px;
}

@media (min-width: 900px) {
    .container > h3 {
        max-width: 600px;
    }
}
/* $$$$$$$$$ SIDE MENU $$$$$$$$$$$$ */

.menu {
  position: fixed;
  background-color: #1a1c1d;
  width: 74px;
  min-height: 100vh;
  padding: 23px auto 23px auto;
  grid-area: menu;
  -webkit-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  transition: all ease 0.4s;
  z-index: 120;
}

@media all and (max-width: 900px) {
  .menu {
    display: none;
  }
}

.menuBg {
  background: #000;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0.3s ease, opacity 0.6s ease;
  -o-transition: visibility 0.3s ease, opacity 0.6s ease;
  transition: visibility 0.3s ease, opacity 0.6s ease;
  z-index: 100;
}

.menuLogoContainer {
  height: 74px;
  width: 100%;
  border-bottom: 2px solid #000;
  padding: 23px 0px;
}

.menu:hover + .menuBg {
  visibility: visible;
  opacity: 0.8;
}

.menuIconActive {
  display: none;
}

.menuItemContainer {
  height: 74px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  cursor: pointer;
  padding: 25px 0px 25px 25px;
}

.icon-container {
  width: 24px;
  height: 24px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.menuLinkText {
  /* display    : none; */
  font-family: "GT-America-Standard" !important;
  font-size: 16px;
  line-height: 140%;
  margin-left: 24px;
  margin-top: 0px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s linear, opacity 0.3s ease;
  -o-transition: visibility 0s linear, opacity 0.3s ease;
  transition: visibility 0s linear, opacity 0.3s ease;
  height: 24px;
  white-space: nowrap;
}

.menu:hover {
  width: 224px;
}

.menuItemActive {
  border-left: 3px solid var(--primary-colour);
  padding-left: 21px;
}

.menuItemActive > .menuLinkText {
  color: #fff;
}

.menu:hover .menuLinkText {
  /* display      : inline; */
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.menuItemContainer:hover .menuLinkText {
  color: #fff;
}

.menuItemContainer:hover .menuIconActive {
  display: inline;
}

.menuItemContainer:hover .menuIcon {
  display: none;
}

.mpLogoNav {
  position: absolute;
  display: inline;
  top: 13px;
  left: 13px;
  -webkit-transition: visibility 0s linear, opacity 0.3s ease;
  -o-transition: visibility 0s linear, opacity 0.3s ease;
  transition: visibility 0s linear, opacity 0.3s ease;
}

.mpLogoTextNav {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 13px;
  left: 13px;
  -webkit-transition: visibility 0s linear, opacity 2s linear;
  -o-transition: visibility 0s linear, opacity 2s linear;
  transition: visibility 0s linear, opacity 2s linear;
}

@media all and (max-width: 900px) {
  .mpLogoTextNav {
    visibility: visible;
    opacity: 1;
  }
}

.menu:hover .mpLogoTextNav {
  visibility: visible;
  opacity: 1;
}

.menuItemChildMenu {
  /* display      : none; */
  visibility: hidden;
  opacity: 0;
  position: relative;
  top: 50px;
  width: 204px;
  left: -58px;
  margin-bottom: 50px;
  -webkit-transition: visibility 0s linear, opacity 2s linear;
  -o-transition: visibility 0s linear, opacity 2s linear;
  transition: visibility 0s linear, opacity 2s linear;
}

.menu:hover .showChildren {
  visibility: visible;
  opacity: 1;
  /* display   : inline; */
}

/* .menuItemContainer:hover>.menuItemChildMenu {
      visibility: visible;
      opacity   : 1;
  } */

.menuProfileContainer {
  position: relative;
}

.menuHasChildren {
  height: 100%;
}

.menuAvatar {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.profileSwitcher {
  position: relative;
  left: -64px;
  /* padding-top: 42px; */
  background: #fff;
  border-radius: var(--corner-radius);
  color: #000;
  width: 100%;
}

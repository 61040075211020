.contentContainer {
  position: relative;
  margin-left: 72px;
  height: 100vh;
  width: 100%;
  padding: 25px 60px;
  display: block;
  margin-bottom: 24px;
  margin-top: 120px;
}

@media all and (max-width: 900px) {
  .contentContainer {
    margin-left: 0px;
  }
}

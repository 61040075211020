
.container {
    width: 100%;
    background-color: #5440F0;
    height: fit-content;
    padding: 8px;
    position: relative;
    min-height: 432px;
}

.container > div {
    background-color: #000;
    border-radius: 8px;
    position: relative;
    height: 100%;
    min-height: 410px;
    padding-top: 52px
}

.info {
    /* max-width: 320px; */
    height: 212px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    z-index: 10;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
    margin: auto;
    width: 100%;
    padding: 0px 32px;
}

.info > * {
    margin: auto;
    text-align: center;
    z-index: 5;
}

.left {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    z-index: 1;
}

.right {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    z-index: 1;
    display: none;
}

.info > button {
    width: 252px;
    font-size: 14px;
}


@media (min-width: 900px) { 

    .right {
        display: inline;
    }
}
.background {
  width: 100vw;
  height: 100vh;
  z-index: 120 !important;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  filter: blur(4px);
  background: #000;
  opacity: 0.7;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: white;
  color: var(--black);
  padding: 24px 32px;
  top: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 150;
}

.container > p {
  text-align: center;
  margin-top: 12px;
  color: var(--body-text-on-white);
}

.container > img {
  width: 72px;
  margin: 32px auto;
}

.container > h3 {
  color: black;
  text-align: center;
}

.container h3 {
  color: black;
  text-align: center;
}

.container > button {
  margin-top: 32px;
  width: 100%;
  min-height: 42px;
  border-radius: 8px;
  height: 38px;
  border-radius: 8px;
  font-family: "GT-America-Standard";
  font-size: 18px;
}

.check_image {
  height: 80px;
  width: 80px;
  margin: 48px auto;
}

.key_benifits {
  margin: 12px 0px;
}

.key_benifits ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 12px;
}

.key_benifits ul li {
  margin: 3px 0px;
  display: inline-flex;
}

.key_benifits ul li p {
  color: var(--body-text-on-white);
  font-size: 14px;
}

.key_benifits ul li img {
  margin-right: 8px;
  margin-top: 3px;
  height: 16px;
}

.reccomended {
  height: 124px;
  background-color: var(--primary-colour);
  position: relative;
  border-radius: 8px 8px 12px 12px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.reccomended > p {
  color: white;
  font-size: 14px;
  margin-left: 16px;
  margin-bottom: 2px;
}

@media (min-width: 900px) {
  .container {
    max-width: 390px;
    border-radius: 8px;
    position: fixed;
    max-height: 480px;
    margin: auto;
    padding: 24px 32px;
    margin-top: 120px;
    left: calc(50% - 195px);
  }

  .check_image {
    margin: 24px auto;
  }

  .key_benifits {
    margin: 24px auto;
  }
  .key_benifits > ul {
    margin: 0px auto;
    padding: 0px 8px;
  }

  .container > button {
    margin-top: 24px;
  }
  .container > img {
    margin: 24px auto;
  }
}

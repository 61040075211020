.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

.loading_container {
  top: 25%;
  position: relative;
  margin: auto;
}

.video_container {
  width: 100%;
  background-color: #000;
  margin: auto;
  position: relative;
  padding: 24px;
  top: 72px;
}

.player_container {
  width: 100%;
  /* height: 220px; */
  height: 100%;
}

.video_player {
  height: 100%;
}

.transcript_container {
  /* background-color: blue; */
  display: none;
}

.description_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-size: 14px;
}

.download_btn {
  background-color: #000;
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--White, #fff);
}

.download_btn:hover {
  background-color: #1a1c1d;
}

@media (min-width: 480px) {
  .title {
    font-size: 24px;
  }

  .container {
  }

  .video_results {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .video_results > div {
    margin-top: 24px;
    margin-left: 12px;
  }

  .player_container {
    /* height: 360px; */
  }
}

@media (min-width: 768px) {
  .video_container {
    width: 100%;
  }

  .player_container {
    width: 100%;
    height: 360px;
  }

  .description_container {
    margin: 12px 24px;
  }
}

@media (min-width: 1024px) {
  .video_container {
    width: 60%;
    top: calc(50% - 210px);
  }

  .transcript_container {
    /* background-color: blue; */
    display: inline;
    width: 40%;
  }
}

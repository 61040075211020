.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #000;
  color: #fff;
}

.company_form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input_container {
  position: relative;
}

.input_container > input {
  font-family: "GT-America-Standard";
  font-size: 14px;
  line-height: 17px;
  height: 42px;
  background-color: black;
  color: #fff;
  border-width: 0px;
  border: 1px var(--body-text-on-white) solid;
  border-radius: 4px;
}

.input_container > textarea {
  padding: 12px;
  font-family: "GT-America-Standard";
  font-size: 14px;
  line-height: 17px;
  /* height: 42px; */
  background-color: black;
  color: #fff;
  border: 1px var(--body-text-on-white) solid;
  border-radius: 4px;
}

.input_container > label {
  color: #fff;
  position: relative;
  float: left;
  font-size: 12px;
  text-transform: uppercase;
}

.submit_button {
  margin-top: 24px;
}

.fundraising {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 18px;
}

.fundraising > span {
  font-family: "GT-America-Extended-Bold";
  font-weight: normal;
  font-style: normal;
  color: #fff;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
}

.fundraising_checkbox > input {
  height: 18px;
}

@media all and (min-width: 900px) {
    .container {
        width: 500px;
    }
}

@media all and (min-width: 1320px) {
}


.buttonAsAnchor {
  padding: 0px;
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  height: 14px;
  line-height: 140%;
  color: var(--primary-colour);
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  background-color: inherit;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.buttonAsAnchor:hover {
  background-color: inherit;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
}

.container > h3 {
    margin-top: 12px;
}

@media all and (min-width: 900px) {
    .container {}
}

@media all and (min-width: 1320px) {
    .container {}
}
.container {
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
  border-radius: 8px;
  margin: 12px 0px;
  margin-bottom: 0px;
}

.container[data-selected="true"] {
  background-color: black;
  border: 1px solid #4837cf;
}

.credit_info {
  position: absolute;
  top: 16px;
  left: 58px;
}

.cost {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cost > h3 {
  text-align: left;
  font-size: 21px;
}

.input_container {
  position: relative;
}

.input_container > input {
  padding: 7px 8px 8px 12px;
  gap: 10px;
  width: 202.47px;
  height: 32px;
  border: 1px solid #bfbfbf;
  border-radius: var(--corner-radius);
  background-color: transparent;
  color: #ffffff;
}
.container[data-selected="true"] > div > .input_container > input {
    border: 1px solid #ffffff;
}
.container[data-selected="true"] > div > .input_container > .credit_info {
    color: #ffffff;
}

.input_container > input[type="number"]::-webkit-inner-spin-button,
.input_container > input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
}

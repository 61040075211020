.objective_notification_container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-right: 48px;
	gap: 24px;
}

.objective_notification_container > h4 {
	width: 100%;
}

.objective_benifits_container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 12px;
}

.objective_benifits_container > div {
	width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.objective_benifits_container > div > img {
	width: 24px;
	height: 24px;
	filter: brightness(0) invert(1);
}

@media all and (min-width: 900px) {
	.objective_notification_container {
		margin-right: 12px;
	}

	.objective_benifits_container {
		flex-direction: row;
		justify-content: space-between;
		gap: 24px;
	}
}

@media all and (min-width: 1320px) {
	.container {
	}
}

.profile_form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.profile_form > div {
    margin-bottom: 16px;
}

.profile_form:last-child {
    margin-bottom: 0px;
}
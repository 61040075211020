.container {
	background-color: var(--module-bg-dark);
	border-radius: var(--corner-radius);
	width: 100%;
	padding: 24px;
	position: relative;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.container > hr {
	height: 2px;
	background-color: #000;
	border: none;
	margin: 0px;
}

.label {
	color: #000000;
	background: none;
	text-align: left;
	float: left;
	position: relative;
	background: none;
	margin-bottom: 12px;
}
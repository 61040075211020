.container {
    width: 212px;
    display: flex;
    flex-direction: row;
    padding: 9px 12px;
    gap: 12px;
    border-radius: 4px;
    height: 36px;;
}

.container > p {
    font-size: 14px;
    color: #BFBFBF;
}

.current_step {
    background-color: #25282A;
}

.current_step > p {
    color: #fff;
}

.check {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 14px;
    height: 14px;
    margin-top: 2px;
}

.active_check {
    position: relative;
    width: 14px;
    height: 14px;
    background: #5440F0;
    border-radius: 50%;
    position: relative;
    border: 1px solid #5440F0;;
}

.active_check > div {
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    position: relative;
}

@media all and (min-width: 900px) {
    .container {

    }
}

@media all and (min-width: 1320px) {
    .container {

    }
}

.container {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-image: url("../../images/people.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.errorContents {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	max-width: 300px;
	margin: auto;
	margin-top: 24%;
	height: 280px;
}

.errorContents > img {
	margin: 0px auto;
}

.errorContents > h4 {
	font-size: 18px;
	line-height: 140%;
	text-align: center;
}

.errorContents > p {
	margin: 0px auto;
	text-align: center;
	font-size: 14px;
}

.buttonContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 124px;
	width: 240px;
	margin: 0px auto;
}

.buttonContainer > button {
    height: 42px;
}

.reportErrorButton {
	background-color: black;
	border: 2px solid #fff;
	color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.reportErrorButton:hover {
	background-color: #fff;
	border: 2px solid #fff;
	color: #000;
    -webkit-box-shadow: none;
    box-shadow: none;
}

@media all and (min-width: 900px) {
	.errorContents > h4 {
		font-size: 24px;
	}
	.errorContents {
		margin-top: 15%;
		height: 320px;
	}
}

.container {
    width: 100%;
    background-color: var(--module-bg-colour);
    padding: 24px;
    border-radius: 4px;
}

.form {
    display: flex;
    gap: 16px;
    flex-direction: column;
}

@media all and (min-width: 900px) {
    .container {

    }
}

@media all and (min-width: 1320px) {
    .container {

    }
}
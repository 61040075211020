.container {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: fit-content;
}

@media all and (min-width: 900px) {
  .container {
  }
}

@media all and (min-width: 1320px) {
  .container {
  }
}

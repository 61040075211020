.container {
  width: auto;
  height: auto;
  background-color: var(--primary-colour);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  object-fit: cover;
  object-position: center;
  border-radius: inherit;
  width: 100%;
  height: 100%;
}

.name {
  color: #ffffff;
}

@media all and (max-width: 900px) {
  .container {
  }
}

@media all and (max-width: 1320px) {
  .container {
  }
}

.container {
	width: 100%;
	display: flex;
	flex-direction: row;
	padding: 8px 0px;
	align-items: space-between;
	border-bottom: 1px solid #25282a;
}

.content {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 4px;
	height: fit-content;
}

.round_checkbox {
	position: relative;
	margin-top: 4px;
	margin-right: 2px;
	height: 24px;
}


.round_checkbox > input {
	height: 42px;
}

.round_checkbox label {
	border: 1px solid #ccc;
	border-radius: 50%;
	cursor: pointer;
	height: 14px;
	left: 0;
	position: absolute;
	top: 0;
	width: 14px;
}

.round_checkbox label:after {
	border: 2px solid #fff;
	border-top: none;
	border-right: none;
	content: "";
	height: 5px;
	left: 2px;
	opacity: 0;
	position: absolute;
	top: 3px;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
	width: 8px;
}

.round_checkbox input[type="checkbox"] {
	visibility: hidden;
}

.round_checkbox input[type="checkbox"]:checked + label {
	background-color: var(--primary-colour);
	border-color: var(--primary-colour);
}

.round_checkbox input[type="checkbox"]:checked + label:after {
	opacity: 1;
}

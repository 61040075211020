.sidebar_actions {
    /* display: none; */
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .sidebar_actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .action_container {
        padding: 24px 0px 52px 0px;
    }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.profile_form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.social_option {
	display: flex;
	flex-direction: row;
}

.social_option > img {
	display: none;
}

.input_container {
	position: relative;
}

.input_container > input {
	font-family: "GT-America-Standard";
	font-size: 14px;
	line-height: 17px;
	height: 42px;
	background-color: black;
	color: #fff;
	border-width: 0px;
	border: 1px var(--body-text-on-white) solid;
	border-radius: 4px;
}

.input_container > textarea {
	font-family: "GT-America-Standard";
	font-size: 14px;
	line-height: 17px;
	/* height: 42px; */
	background-color: black;
	color: #fff;
	border: 1px var(--body-text-on-white) solid;
	border-radius: 4px;
}

.input_container > label {
	color: #fff;
	position: relative;
	float: left;
	font-size: 12px;
	text-transform: uppercase;
}

.submit_btn {
    margin-top: 18px;
}


@media all and (min-width: 900px) {
    .container {
        width: 500px;
    }
	.social_option {
		display: flex;
		flex-direction: row;
	}
	
	.social_option > img {
		display: block;
		margin-top: 2px;
		margin-right: 12px;
		height: 18px;
		width: 18px;
	}
}

@media all and (min-width: 1320px) {
    .container {}
}
.container {
	width: 100%;
	background-color: #e5e5e5;
}

.heading {
	margin: auto;
	width: 100%;
	text-align: left;
	padding: 16px;
}

.tagline {
	margin: auto;
	text-align: left;
	font-family: "GT-America-Extended-Bold";
	font-size: 14px;
}

.tagline > span {
	color: var(--primary-colour);
	font-family: "GT-America-Extended-Bold";
	font-size: 14px;
}

.heading > h2 {
	color: #000;
	text-align: left;
	margin-bottom: 16px;
	margin-top: 8px;
}

.heading > p {
	display: none;
}

.wrapper {
	max-width: 1260px;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	flex-flow: wrap;
}

.faq_item {
	box-sizing: border-box;
	padding: 0 12px 16px;
	width: 100%;
}

@media (min-width: 900px) {
	.faq_item {
		box-sizing: border-box;
		width: 50%;
		padding: 0 28px 16px;
	}

	.container {
		padding: 72px 36px;
	}

	.heading {
		margin: auto;
		width: 100%;
		text-align: center;
		padding: 16px 0 48px 0px;
	}

	.tagline {
		text-align: center;
	}

	.heading > h2 {
		text-align: center;
		margin-bottom: 24px;
		margin-top: 12px;
	}

	.heading > p {
		display: inline;
		color: #66686a;
		text-align: center;
		max-width: 520px;
		margin: auto;
	}
}

.container {
	width: 100%;
	height: 100vh;
	background-image: url("../../../images/people.png");
	background-repeat: no-repeat;
	background-size: cover;
}
.body {
	width: 100%;
	background-color: var(--section-bg-colour);
	color: var(--black);
	padding: 24px 32px;
	height: 100vh;
}

.login_header {
	display: flex;
	/* justify-content: space-around; */
	flex-direction: column;
}

.login_header > img {
	height: 42px;
	margin: 24px auto;
}

.login_header > p {
	text-align: center;
	margin-top: 6px;
}

.login_header > p > span {
	color: var(--primary-colour);
	cursor: pointer;
}

.form {
	margin-top: 12px;
}

.form > div {
	margin: 12px 0px;
}

.options_container {
	margin-top: 0px !important;
	height: 64px;
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
}

.loading_text {
	color: var(--black);
	text-align: center;
	padding-bottom: 12px;
}

.submit_button {
	height: 38px;
	border-radius: 8px;
	font-family: "GT-America-Standard";
	font-size: 18px;
}

.forgotten_password {
	margin-top: 12px;
	text-align: right;
	font-size: 14px;
	cursor: pointer;
}

.creating_profiles_loading {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

@media (min-width: 900px) {
	.container {
		width: 100%;
		height: 100vh;
		background-color: black;
	}

	/* .auth_container {
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		justify-content: center;
		margin-top: 15vh;
		background-color: var(--section-bg-colour);
		padding: 24px 32px;
		border-radius: 8px;
	} */

	.body {
		margin: 64px auto;
		max-width: 420px;
		padding: 24px 32px;
		border-radius: 8px;
		height: fit-content;
		max-height: 724px;
	}
	/* 
	.login_header img {
		height: 68px;
	} */
}
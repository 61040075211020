.container {
  display: flex;
  width: 314px;
  padding: 16px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 4px;
  background: var(--White, #fff);
  cursor: pointer;
}

.title {
  color: var(--black-standard, #000);
  font-feature-settings: "clig" off, "liga" off;
  font-family: GT America;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.subheading {
  margin-top: 2px;
  color: var(--grey-text-on-white, #66686a);
  /* Body / Regular - 12 */
  font-family: GT America;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media all and (min-width: 900px) {
  .container {
  }
}

@media all and (min-width: 1320px) {
  .container {
  }
}


.missedSession {
	/* display: flex;
  justify-content: center;
  align-items: center; */
	width: 100%;
	height: 100vh;
	margin-top: 12%;
}

.step {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	max-width: 720px;
	margin: auto;
}

.label {
	font-size: 12px;
	font-family: var(--bold-font);
	color: var(--body-text-on-white);
}

.title {
	font-size: 18px;
	font-family: var(--bold-font);
	color: #fff;
	margin-top: 10px;
}

.description {
	font-size: 14px;
	margin-top: 15px;
	color: var(--body-text-on-black);
	max-width: 370px;
	text-align: center;
}

.content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	width: 100%;
	margin: 30px 0px;
}

.content > .listItem {
	margin-top: 8px;
}

.content > div,
.messageContent {
	width: 80%;
}

.listItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	background-color: var(--module-bg-colour);
	height: 60px;
	padding: 15px;
	border-radius: var(--corner-radius);
	width: 100%;
	cursor: pointer;
}

.listItem > span {
	color: #fff;
	min-width: 30px;
	background-color: var(--black);
	height: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	border-radius: var(--corner-radius);
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	position: relative;
}

.listItem > p {
	margin-left: 17px;
	font-family: var(--bold-font);
	font-size: 16px;
	color: #fff;
}

.primeRect {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	width: 160px;
	height: 160px;
	background-color: var(--primary-colour);
	border-radius: var(--corner-radius);
}

.darkCircle {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	width: 65px;
	height: 65px;
	border-radius: 50%;
	background-color: var(--primary-colour-presses);
}

.messageContent > p {
	margin-top: 15px;
	text-align: center;
}

.messageContent > h2 {
	margin-top: 25px;
	text-align: center;
}

.button {
	margin-top: 25px;
}

.skipBtn,
.skipBtn:hover {
	margin-top: 17px;
	font-size: 12px;
	font-family: var(--bold-font);
	padding: 0px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	background-color: var(--black);
}

.skipBtn:focus {
	outline: none;
}

.selected {
	background-color: var(--primary-colour);
}

.disabledBtn,
.disabledBtn:hover {
	color: var(--body-text-on-white);
	background-color: var(--module-bg-colour);
	cursor: not-allowed;
	pointer-events: none;
}

@media all and (max-width: 900px) {
	.step,
	.content {
		width: 100%;
	}
	.step > button {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.title {
		width: 100%;
		padding: 0px 6px;
		text-align: center;
	}
}
.round_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* background-color: var(--black); */
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  font-size: 16px;
  line-height: 130%;
  -webkit-filter: drop-shadow(0px 4.375px 7.875px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(0px 4.375px 7.875px rgba(0, 0, 0, 0.7));
  width: 52px;
  height: 52px;
  /* COLOURING */
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(67, 51, 192, 0.9) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
  border: 1px solid #fff;
}

.round_btn:hover {
  background-color: var(--black);
}

.active_round_btn {
  composes: round_btn;
  background: #000;
  border: 1px solid #fff;
  /* border: 1px solid #ddd; */
}

.loading_button {
  composes: round_btn;
  background: radial-gradient(
    30% 30% at 50% 50%,
    rgba(67, 51, 192, 0.9) 10%,
    rgba(0, 0, 0, 0.9) 100%
  );
  animation: pulse 1.1s ease-in-out infinite alternate;
  background-position: center;
  border: 2px solid #1a1c1d;
}
/* loading button animation */
@keyframes pulse {
  from {
    background-size: 52px 52px;
  }
  to {
    background-size: 104px 104px;
  }
}

.avatarWindow {
  width: 100%;
  height: 100%;
  background-color: var(--module-bg-dark);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 0;
  left: 0;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: var(--corner-radius);
  z-index: 100;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.5),
    0 8px 20px 0 rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.5), 0 8px 20px 0 rgba(0, 0, 0, 0.5) !important;
  overflow-y: auto;
  position: fixed;
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card {
  background: url("../../../../images/mentorpass-card.svg") no-repeat center
    center black;
  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  height: 181px;
  width: 282px;
  color: #fff;
  margin: 16px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px;
  border-radius: 7px;
}

.cardContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 150px;
  width: 100%;
}
.cardLeft {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.cardRight {
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-right: 20px;
}

.cardCenter {
  font-size: 54px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: var(--bold-font);
}

.darkButton {
  background-color: var(--black);
  margin-top: 8px;
  border: 1px solid #fff;
  -webkit-box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.25);
  border-radius: var(--corner-radius);
  font-family: "GT-America-Extended-Bold";
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  height: 42px;
  padding: 12px 20px;
  cursor: pointer;
  text-align: center;
}

.button {
  background-color: var(--primary-colour);
  -webkit-box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.25);
  border-radius: var(--corner-radius);
  font-family: "GT-America-Extended-Bold";
  color: #fff;
  border: none;
  font-size: 14px;
  line-height: 17px;
  height: 42px;
  padding: 12px 20px;
  cursor: pointer;
  text-align: center;
}

.darkButton:hover {
  background-color: var(--black);
  color: #fff;
}
.button:hover {
  background-color: var(--primary-colour);
  color: #fff;
}

.creditAvatarPosition {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
}

.action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.creditText {
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-family: var(--bold-font);
  text-transform: uppercase;
}

.helperText {
  font-size: 12px;
  margin-top: 4px;
  color: var(--body-text-on-black);
  font-family: var(--regular-font);
}
.title {
  font-size: 14px;
  color: #fff;
  font-family: var(--bold-font);
}

.creditAvatarDesktop {
  position: relative;
}

@media (max-width: 900px) {
  .round_btn {
    position: fixed;
    bottom: 24px;
    left: 24px;
    border: 2px solid #c4c4c4;
  }
  .active_round_btn {
    position: fixed;
    bottom: 24px;
    left: 24px;
  }

  .ldh_mobile_credit {
    display: block;
    position: fixed;
    z-index: 9;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .creditAvatarPosition {
    display: block;
    position: fixed;
    z-index: 9;
    bottom: 25px;
    right: 25px;
  }

  .avatarWindow {
    position: fixed;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@media (min-width: 768px) {
  .avatarWindow {
    width: 100%;
    height: 100%;
    background-color: var(--module-bg-dark);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: var(--corner-radius);
    z-index: 100;
    -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.5),
      0 8px 20px 0 rgba(0, 0, 0, 0.5) !important;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.5),
      0 8px 20px 0 rgba(0, 0, 0, 0.5) !important;
    position: relative;
  }

  .container {
    padding: 24px 24px;
  }
  .title {
    font-size: 24px;
  }
  .remaining_credits {
    font-size: 14px;
  }
  .offers {
    flex-direction: row;
    justify-content: space-between;
  }
  .offers > div {
    margin-bottom: 0px;
  }
  .offers {
    padding-bottom: 12px;
  }
}

.container {
  width: 100%;
  height: 90vh;
}

.completed {
  position: absolute; /* Positioning context */
  top: 40%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust to exact center */
  width: 100%; /* Width of a small mobile screen */
  padding: 18px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-content: center;
}

.completed > img {
  width: 180px;
  margin: 0px auto;
}

.completed > h2 {
  font-size: 24px;
  font-weight: 600;
  margin-top: -18px;
  cursor: default;
}

.completed > ul {
  margin-top: 36px;
  cursor: default;
  padding-left: 24px;
}

.completed > ul > li {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
  cursor: default;
}

@media all and (min-width: 900px) {
  .completed {
    width: 720px;
  }
  .completed > h2 {
    text-align: center;
  }
}

.successPage {
  max-width: 480px;
  height: 420px;
  margin: auto;
  margin-top: 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 12px;
}

.successPage>p {
  text-align: center;
  margin-top: 12px;
  margin-bottom: 18px;
}

.successPage>h2 {
  text-align: center;
}

.successAvatar {
  height: 160px;
  width: 160px;
  position: relative;
  margin: 0px auto;
  margin-bottom: 12px;
}

.successAvatarIcon {
  position: absolute;
  top: -20px;
  right: -20px;
  padding: 8px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #fff;
  z-index: 20;
}

.successAvatarIcon>img {
  color: #fff;
}

.successAvatarImage {
  width: 100%;
  height: 100%;
  border-radius: var(--corner-radius);
}

.declineButton {
  float: right;
}

.loading_section {
  padding-top: 220px;
  margin: auto;
}

@media (max-width: 900px) {
  .successPage {
    width: 100%;
  }
}
.menu {
  position: fixed;
  width: 100vw;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 74px);
  left: -100vw;
  -webkit-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  transition: all ease 0.4s;
  padding: 24px;
  justify-content: space-between;
  top: 74px;
  background-color: #000;
  z-index: 100;
}

.open {
  left: 0px;
}

.menu_item {
  font-family: var(--bold-font);
  font-size: 16px;
  line-height: 140%;
  margin-top: 0px;
  height: 24px;
  white-space: nowrap;
  color: #fff;
  padding: 24px 0px;
  justify-content: center;
  text-align: center;
  margin: auto;
  width: fit-content;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.menu_item:hover {
  border-bottom: 1px solid #fff;
}

.menu_item_active {
  border-bottom: 1px solid var(--primary-colour-hover);
}

.logout {
  margin-bottom: 16px;
  width: 100%;
}

@media all and (min-width: 900px) {
  .menu {
    display: none;
  }
}

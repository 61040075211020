.container {
  position: relative;
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
}

.dropdownButton {
  background: #fff;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 3px;
  padding-left: 20px;
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  justify-content: space-between;
  margin-bottom: 12px;
  color: #000;
}

.open .dropdownButton {
  border-radius: 3px 3px 0px 0px;
}

.dark .dropdownButton {
  background: #000;
  color: #c4c4c4;
}

.label {
  color: #000000;
  background: none;
  text-align: left;
  float: left;
  position: relative;
  background: none;
  margin: 12px 0px;
}

.dark .label {
  color: #c4c4c4;
}

.chevron {
  margin-right: 13px;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.dropdownOptions {
  border: 1px solid #5440f0;
  background: #fff;
  box-sizing: border-box;
  line-height: 19px;
  z-index: 40;
  position: absolute;
  border-radius: 0px 0px 3px 3px;
  width: 100%;
  margin-top: -13px;
  max-height: 304px;
  overflow-y: scroll;
}

.dark .dropdownOptions {
  background: var(--black);
}

.item {
  margin: 10px !important;
  border-radius: 3px;
  padding: 10px 10px;
  color: var(--body-text-on-white);
  text-align: left;
  background: #ffffff;
}

.item:hover {
  background: var(--field-outline-colour);
  color: #000000;
}

.dark .item {
  color: var(--body-text-on-black);
  background: var(--black);
}

.dark .item:hover {
  background: var(--module-bg-colour);
  color: #ffffff;
}

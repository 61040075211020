.container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.dropdown {
  width: 192px;
}

.button {
  border: 1px var(--body-text-on-white) solid;
  background: #000;
  box-sizing: border-box;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  border-radius: 3px;
  padding-left: 12px;
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  justify-content: space-between;
  color: #c4c4c4;
  border-radius: 3px 0px 0px 3px;
  margin: 0;
}

.open {
  border-radius: "3px 3px 0px 0px";
  border: "1px solid #5440f0";
}

.options {
  border: 1px solid #5440f0;
  background: var(--black);
  box-sizing: border-box;
  font-family: "GT-America-Standard";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  z-index: 40;
  position: absolute;
  border-radius: 0px 0px 3px 3px;
  width: 100%;
  margin-top: -1px;
  max-height: 304px;
  overflow-y: scroll;
  width: 192px;
}

.chevron {
  margin-right: 13px;
  /* // margin-top: -8px; */
  width: 16px;
  height: 16px;
  position: relative;
  right: 0px;
}

.label {
  color: #ffffff !important;
  background: none;
  text-align: left;
  float: left;
  position: relative;
  background: none;
  margin: 12px 0px;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 14px;
}

.item {
  margin: 10px !important;
  border-radius: 3px;
  padding: 10px 10px;
  color: var(--body-text-on-black);
  text-align: left;
  background: var(--black);
}

.item:hover {
  background: var(--module-bg-colour);
  color: "#fff";
}

.text_input {
  margin-top: 38px;
  background-color: #000 !important;
  border: 1px var(--body-text-on-white) solid !important;
  border-radius: 0px 3px 3px 0px !important;
  border-left: 0px !important;
  color: var(--body-text-on-black) !important;
  position: relative;
  margin-top: 38px !important;
  height: 42px !important;
}

@media all and (min-width: 900px) {
	
	.button {
		width: 192px;
		padding-left: 20px;
	}

	.text_input {

	}
}
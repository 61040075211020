.testimonial {
	background-color: #fff;
	/* height: 64px; */
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 42px 42px;
}

.testimonial > h3 {
	color: #000;
	font-size: 18px;
	text-align: center;
}

.testimonial > div {
	margin: 12px auto 0px auto;
	display: flex;
	flex-direction: row;
}

.testimonial > div > p {
	margin: 3px 10px;
}

.testimonial > div > img {
	height: 32px;
	width: 32px;
}

.bg {
    width: 100%;
    background-color: #F4F4F4;
}

.container {
    margin: auto;
    padding: 48px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.left_container {
    width: 100%;
}

.left_container > h3 {
    text-align: center;
    width: 194px;
    margin: auto;
    color: #000;
    margin-bottom: 18px;
}

.card_container {
    width: 100%;
    padding: 12px;
}

.card_container > div {
    margin-bottom: 24px;
}

.card_container > div:last-child {
    margin-bottom: 0px;
}

@media (min-width: 900px) {
    .container {
        max-width: 1042px;
        padding: 72px 0px 72px 0px;
        flex-direction: row;
    }

    .left_container {
        width: 288px;
    }

    .left_container > h3 {
        text-align: left;
        margin: 0px;
    }

    .card_container {
        padding: 0px;
        width: fit-content;
    }
}
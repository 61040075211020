.pill {
  background-color: var(--black);
  color: var(--body-text-on-black);
  padding: 6px 15px;
  height: 32px;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;     
}

.pill:hover {
  border: 1px solid var(--primary-colour-hover);
}
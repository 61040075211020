.container {
  width: 100%;
  padding: 12px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container > div {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.update_button {
  padding: 6px 12px !important;
  /* width: 72px !important; */
  /* height: 26px !important; */
  background: #000000;
  /* border-radius: 28px !important; */
  margin: 10px 0px !important;
  line-height: 14px !important;
  font-size: 12px !important;
}

.no_method_exists {
  border: var(--error-colour-dark) 2px solid;
  border-radius: 8px;
  padding: 12px;
}
